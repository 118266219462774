<div class="flex h-screen hero bg-no-repeat bg-cover" style="background-image: url('assets/background.svg');">

  <div class="w-[60%] h-full flex flex-col">
    <div class="flex-1 flex items-center justify-center">
      <img class="h-[460px]" src="assets/hero.svg" />
    </div>
    <div class="p-16">
      <img src="assets/testimonials.svg" />
    </div>
  </div>

  <div class="w-[40%] h-full p-2">
    <div class="w-full h-full p-8 bg-white rounded-lg flex flex-col items-center justify-center">
      <img class="h-[40px] mb-16" src="assets/logo-black.svg" />
      <form class="w-full max-w-md" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-control mb-4">
          <input
            type="email"
            formControlName="email"
            placeholder="Email"
            autocomplete="username"
            required
            autofocus
            class="input input-bordered w-full bg-white" />
        </div>
        <button
          type="submit"
          class="btn btn-accent text-white w-full mt-4">Reset Password</button>
        <div class="text-center mt-4">
          <a [routerLink]="['/login']" class="text-gray-600">Back to Sign In</a>
        </div>
      </form>
    </div>
  </div>
</div>
