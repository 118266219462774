<div class="flex h-screen">
  <div class="w-[260px] side-bar z-50 p-6 flex flex-col h-screen fixed left-0 top-0">
    <div class="text-left pb-6">
      <img src="assets/logo-white.svg" class="h-[24px] mb-2" />
    </div>
    <ul class="menu p-0 border-t border-gray-700 pt-6">
      <li><a class="p-3" [routerLink]="['/']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"><svg class="h-4" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path fill="currentColor" d="M19,24H5c-2.757,0-5-2.243-5-5V9.724c0-1.665,.824-3.215,2.204-4.145L9.203,.855c1.699-1.146,3.895-1.146,5.594,0l7,4.724c1.379,.93,2.203,2.479,2.203,4.145v9.276c0,2.757-2.243,5-5,5ZM12,1.997c-.584,0-1.168,.172-1.678,.517L3.322,7.237c-.828,.558-1.322,1.487-1.322,2.486v9.276c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3V9.724c0-.999-.494-1.929-1.321-2.486L13.678,2.514c-.51-.345-1.094-.517-1.678-.517Z"/></svg>Dashboard</a></li>
      <li><a class="p-3 mt-2" [routerLink]="['/payers']" routerLinkActive="active-link"><svg class="h-4" id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path fill="currentColor" d="m4 13h3v2h-3zm5 2h3v-2h-3zm-5 4h3v-2h-3zm5 0h3v-2h-3zm-5-12h3v-2h-3zm5 0h3v-2h-3zm-5 4h3v-2h-3zm5 0h3v-2h-3zm15-3v16h-24v-21a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v2h5a3 3 0 0 1 3 3zm-10-5a1 1 0 0 0 -1-1h-10a1 1 0 0 0 -1 1v19h12zm8 5a1 1 0 0 0 -1-1h-5v15h6zm-4 7h2v-2h-2zm0 4h2v-2h-2zm0-8h2v-2h-2z"/></svg>Payers</a></li>
      <li *ngIf="isNsVisible"><a class="p-3 mt-2" [routerLink]="['/network-status']" routerLinkActive="active-link"><svg class="h-4" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path fill="currentColor" d="M12,24c-.5,0-1-.12-1.46-.37l-7.97-4.27c-.98-.52-1.58-1.54-1.58-2.64V7.26c0-1.11,.61-2.12,1.59-2.65L10.55,.36c.91-.49,1.99-.49,2.9,0l7.96,4.25c.98,.52,1.59,1.54,1.59,2.65v9.46c0,1.11-.61,2.12-1.58,2.64l-7.96,4.27c-.46,.24-.96,.37-1.46,.37Zm0-22c-.17,0-.35,.04-.51,.13L3.53,6.38c-.33,.17-.53,.51-.53,.88v9.46c0,.37,.2,.71,.53,.88l7.97,4.27c.32,.17,.7,.17,1.02,0l7.96-4.27c.33-.17,.53-.51,.53-.88V7.26c0-.37-.2-.71-.53-.88h0L12.51,2.13c-.16-.08-.33-.13-.51-.13Z"/></svg>Network Status</a></li>
      <li><a class="p-3 mt-2" [routerLink]="['/providers']" routerLinkActive="active-link"><svg class="h-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path fill="currentColor" d="m19 5h-3v-1a3 3 0 0 0 -3-3h-2a3 3 0 0 0 -3 3v1h-3a5.006 5.006 0 0 0 -5 5v8a5.006 5.006 0 0 0 5 5h14a5.006 5.006 0 0 0 5-5v-8a5.006 5.006 0 0 0 -5-5zm-9-1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1h-4zm12 14a3 3 0 0 1 -3 3h-14a3 3 0 0 1 -3-3v-8a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3zm-6-4a1 1 0 0 1 -1 1h-2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 1 1z"/></svg>Providers</a></li>
    </ul>
    <ul class="menu mt-auto text-white border-t border-gray-700 pt-6">
      <li><a class="p-3" [routerLink]="['webhooks']" routerLinkActive="active-link"><svg class="h-4" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
  <path fill="currentColor" d="M18,18.5h-6.189c-.675,2.63-3.058,4.5-5.81,4.5-3.252,0-6-2.748-6-6,0-1.479,.543-2.899,1.528-4,.009-.01,1.203-1.059,1.901-1.382,1.005-.465,1.999,.7,1.438,1.655-.484,.824-.912,1.515-1.103,1.728-.493,.55-.764,1.26-.764,1.999,0,1.598,1.402,3,3,3,1.444,0,2.684-1.028,2.948-2.444,.223-1.191,1.254-2.056,2.453-2.056h6.598c.829,0,1.5,.671,1.5,1.5s-.671,1.5-1.5,1.5Zm-13.286-2.272c-.426,.71-.196,1.632,.514,2.058,.242,.145,.508,.214,.771,.214,.51,0,1.006-.26,1.288-.728l3.22-5.366c.615-1.025,.402-2.358-.504-3.168-.853-.763-1.189-1.901-.898-3.046,.255-1.007,1.069-1.825,2.073-2.084,1.102-.282,2.215,.017,2.979,.806,.206,.213,.925,1.346,1.74,2.674,.598,.975,2.105,.557,2.105-.587,0-2.641-1.679-4.163-1.689-4.174-1.525-1.574-3.724-2.184-5.884-1.624-2.049,.529-3.71,2.198-4.231,4.251-.53,2.088,.056,4.258,1.53,5.756l-3.011,5.018Zm19.153-.502c-.488-2.343-2.405-4.203-4.77-4.627-.942-.168-1.903-.117-2.801,.147l-3.01-5.017c-.426-.71-1.348-.941-2.058-.515-.71,.426-.941,1.348-.515,2.058l3.218,5.364c.615,1.025,1.895,1.465,3.04,1.045,.507-.185,1.046-.229,1.597-.129,1.153,.207,2.125,1.147,2.362,2.287,.194,.931-.02,1.837-.602,2.553-.573,.705-1.422,1.109-2.329,1.109h-2.487c-1.178,0-1.58,1.535-.577,2.154,1.372,.846,3.043,.846,3.065,.846,1.814,0,3.511-.808,4.657-2.217s1.587-3.252,1.21-5.058Z"/>
</svg>Webhooks</a></li>
      <li><a class="p-3 mt-2" href="https://docs.soharhealth.com" target="_blank"><svg class="h-4" id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path fill="currentColor" d="m17 14a1 1 0 0 1 -1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm-4 3h-5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2zm9-6.515v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486a6.951 6.951 0 0 1 2.051 4.949zm-6.949-7.021a5.01 5.01 0 0 0 -1.051-.78v4.316a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0 -.781-1.05zm4.949 7.021c0-.165-.032-.323-.047-.485h-4.953a3 3 0 0 1 -3-3v-4.953c-.162-.015-.321-.047-.485-.047h-4.515a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z"/></svg>Documentation</a></li>
      <li><a class="p-3 mt-2" href="https://soharhealth.zendesk.com" target="_blank">
        <svg class="h-4" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path fill="currentColor" d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z"/><path fill="currentColor" d="M12.717,5.063A4,4,0,0,0,8,9a1,1,0,0,0,2,0,2,2,0,0,1,2.371-1.967,2.024,2.024,0,0,1,1.6,1.595,2,2,0,0,1-1,2.125A3.954,3.954,0,0,0,11,14.257V15a1,1,0,0,0,2,0v-.743a1.982,1.982,0,0,1,.93-1.752,4,4,0,0,0-1.213-7.442Z"/><rect fill="currentColor" x="11" y="17" width="2" height="2" rx="1"/></svg>
        Support
      </a></li>
      <li>
        <a class="p-3 mt-2" [routerLink]="['settings']" routerLinkActive="active-link"><svg class="h-4" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path fill="currentColor" d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z"/><path fill="currentColor" d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z"/></svg>Settings</a>
      </li>
    </ul>
  </div>
  <div class="flex-1 ml-[260px] mt-[80px] overflow-visible min-h-screen">
    <router-outlet></router-outlet>
  </div>
</div>


