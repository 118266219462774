import { Component } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PayerService } from "../@shared/services/payer.service"
import { Payer } from "../@shared/models/payer"
import { ALL_STATES } from '../@shared/const/states';
import { ProviderService } from '../@shared/services/provider.service';

@Component({
  selector: 'app-create-provider-record',
  templateUrl: './create-provider-record.component.html'
})
export class CreateProviderRecordComponent {

  form: UntypedFormGroup
  payers: Payer[] = []
  states: string[] = ALL_STATES

  isSubmitting = false

  constructor(
    private formBuilder: UntypedFormBuilder,
    private payerService: PayerService,
    private providerService: ProviderService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  async ngOnInit() {
    const payerId = this.route.snapshot.queryParams["payerId"] || ""
    const state = this.route.snapshot.queryParams["state"] || ""

    this.form = this.formBuilder.group({
      payerId: [payerId, [Validators.required]],
      state: [state, [Validators.required]],
      npi: ["", [Validators.required]]
    })

    this.payers = await this.payerService.getPayers()

    if (payerId == "") {
      return
    }

    for (const payer of this.payers) {
      if (payerId == payer.payerId) {
        break
      }

      const index = payer.aliasPayerIds.indexOf(payerId)

      if (index == -1) {
        continue
      }

      this.form.controls["payerId"].setValue(payer.payerId)

      break
    }
  }

  async onSubmit(form: FormGroup) {
    if (form.invalid) {
      return
    }

    this.isSubmitting = true

    const payerId = form.value.payerId
    const state = form.value.state
    const npi = form.value.npi

    try {
      await this.providerService.createCustomerProviderRecord({
        payerId,
        state,
        npi
      })

      this.router.navigate(['providers']);
    } catch (error: any) {
      alert("Something went wrong, please review the information provided in the form and try again")
    } finally {
      this.isSubmitting = false
    }
  }

  onPayerSelected(selectedPayer: Payer) {
    this.form.patchValue({ payerId: selectedPayer.payerId });
  }
}
