import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { map } from 'rxjs/operators'
import { CreateCustomerProviderRecord, CustomerProviderFilters, CustomerProviderFilterValues, CustomerProviderRecord, Provider } from '../models/provider';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(
    private networkService: NetworkService
  ) {}

  async getCustomerProviders(filters: CustomerProviderFilters): Promise<{ total: number, records: CustomerProviderRecord[]}> {
    return new Promise((resolve, reject) => {

      this.networkService.get(`providers`, {
        params: new HttpParams({
          fromObject: {
            ...filters
          }
        })
      }).pipe(
        map((response: any) => {
          return response
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getCustomerProvidersFilterValues(): Promise<CustomerProviderFilterValues> {
    return new Promise((resolve, reject) => {

      this.networkService.get(`providers/filters`).pipe(
        map((response: any) => {
          return response.values
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createCustomerProviderRecord(body: CreateCustomerProviderRecord): Promise<{ total: number, records: CustomerProviderRecord[]}> {
    return new Promise((resolve, reject) => {

      this.networkService.post(`providers`, body).pipe(
        map((response: any) => {
          return response
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async deleteCustomerProviderRecord(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.delete(`providers/${id}`).subscribe({
        next: resolve,
        error: reject
      })
    })
  }
}
