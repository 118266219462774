import { Component, Input } from '@angular/core'
import { NetworkRecordsService } from '../@shared/services/network-records.service'
import { NetworkStatusEvent, NetworkStatusRecord, NetworkStatusRecordFilters, NetworkStatusRecordFiltersValues, NetworkStatusRecordStatus } from '../@shared/types/network-records'
import { Payer } from '../@shared/models/payer'
import { ActivatedRoute, Router } from '@angular/router'
import { getPages } from "../@shared/helpers/pages"

@Component({
  selector: 'app-network-records',
  templateUrl: './network-records.component.html',
  styles: [`
    img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
    }
    .pointer {
      cursor: pointer;
    }
  `]
})
export class NetworkRecordsComponent {
  @Input()
  payers: Payer[]
  @Input()
  filters?: { name: string, values: string}[]
  @Input()
  inputFilters: Omit<NetworkStatusRecordFilters, "offset"> = {}

  allFilters = [
    {
      name: 'status',
      values: 'Statuses'
    },
    {
      name: 'payerId',
      values: 'Payers'
    },
    {
      name: 'state',
      values: 'States'
    },
    {
      name: 'networkType',
      values: 'Insurance Type Codes'
    }
  ]

  records: NetworkStatusRecord[] = []
  eventsById: { [id: number]: NetworkStatusEvent[] } = {}
  filterValues = {}
  selectedFilters: Omit<NetworkStatusRecordFilters, "offset"> = {}

  statusValues = Object.values(NetworkStatusRecordStatus)
  totalRecords: number = 0
  loading = false

  pages: number[] = []
  currentPage = 1

  constructor(
    private networkRecordsService: NetworkRecordsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.loading = true
    const queryParams: any = this.route.snapshot.queryParams

    if (queryParams) {
      this.selectedFilters = {
        status: this.inputFilters.status ?? queryParams.status ?? undefined,
        payerId: this.inputFilters.payerId ?? queryParams.payerId ?? undefined,
        state: this.inputFilters.state ?? queryParams.state ?? undefined,
        networkType: this.inputFilters.networkType ?? queryParams.networkType ?? undefined
      }
      this.cleanSelectedFilters()
    }

    const filterValues = await this.networkRecordsService.getNetworkRecordsFiltersValues()

    this.filterValues = {
      "Insurance Type Codes": filterValues.networkTypes.map(val => ({ option: val, value: val})),
      States: filterValues.states.map(val => ({ option: val, value: val})),
      Payers: filterValues.payerIds.map(val => ({ option: this.getPayerName(val), value: val})),
      Statuses: this.statusValues.map(val => ({ option: val, value: val}))
    }

    this.selectedFilters["status"] = "pending"

    if (this.filters == undefined || this.filters.length == 0) {
      this.filters = this.allFilters
    }

    this.loading = false
    await this.getRecords(1)
  }

  async getRecords(page: number) {
    if (this.loading) {
      return
    }
    this.currentPage = page
    this.records = []
    this.loading = true
    const { records, total } = await this.networkRecordsService.getNetworkRecords({ offset: (page - 1) * 100, ...this.selectedFilters })

    this.records = this.records.concat(records)
    this.totalRecords = total
    this.loading = false
    this.pages = getPages(this.totalRecords, 100, page)
  }

  private getPayerName(payerId: string) {
    return this.payers.find((payer: Payer) => payer.payerId === payerId || payer.aliasPayerIds.includes(payerId))?.payerName
  }

  async toggleEventDetails(record: NetworkStatusRecord) {
    this.loading = true
    if (!this.eventsById[record.id]) {
      const events = await this.networkRecordsService.getNetworkStatusEventsForRecord(`${record.id}`)
      this.eventsById[record.id] = events
    }
    record.isExpanded = !record.isExpanded
    this.loading = false
  }

  async didSelectFilter() {
    this.loading = true
    this.cleanSelectedFilters()
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { ...this.selectedFilters },
      fragment: this.route.snapshot.fragment
    })
    this.records = []
    this.loading = false
    await this.getRecords(1)
  }

  private cleanSelectedFilters() {
    this.selectedFilters = Object.fromEntries(
      Object.entries(this.selectedFilters).filter(([_, value]) => value !== undefined)
    )
  }

  async didSelectStatus(record: NetworkStatusRecord) {
    this.loading = true
    await this.networkRecordsService.updateNetworkStatusRecords(record.status as NetworkStatusRecordStatus, [record.id])
    this.records = []
    this.loading = false
    await this.getRecords(1)
  }

  didClickSelectAll() {
    const selectedValue = this.areAllSelected() ? false : true
    this.records = this.records.map((record: NetworkStatusRecord) => ({
      ...record,
      isSelected: selectedValue
    }))
  }

  areAllSelected() {
    return this.records.length > 0 && this.records.length == this.getAmountOfSelectedRecords()
  }

  getAmountOfSelectedRecords() {
    return this.records.filter((record: NetworkStatusRecord) => record.isSelected).length
  }

  async didChangeStatusSelection(event: Event) {
    this.loading = true
    const status = (event.target as HTMLSelectElement).value
    const recordsIds = this.records.filter((record: NetworkStatusRecord) => record.isSelected).map((record: NetworkStatusRecord) => record.id)

    await this.networkRecordsService.updateNetworkStatusRecords(status as NetworkStatusRecordStatus, recordsIds)
    this.records = []
    this.loading = false
    await this.getRecords(1)
  }
}
