import { Component, Input, Output, EventEmitter, OnInit, HostListener, ElementRef, ChangeDetectorRef, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Payer } from '../../models/payer';

@Component({
  selector: 'app-select-payer',
  templateUrl: './select-payer.component.html'
})
export class SelectPayerComponent implements OnInit, OnChanges {
  @Input() payers: Payer[] = [];
  @Input() selectedPayerId: string;
  @Input() class = ''; // allows passing custom class
  @Output() onChange = new EventEmitter<Payer>();

  @ViewChild("searchField", {static: false}) searchField: ElementRef

  displayedPayers: Payer[] = [];
  filteredPayers: Payer[] = [];
  pageSize = 50;
  currentPage = 0;
  dropdownOpen = false;
  searchTerm: string = '';

  constructor(private eRef: ElementRef, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.payers.length > 0) {
      this.filteredPayers = [...this.payers];
      this.loadInitialPayers();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['payers'] && this.payers.length > 0) {
      this.filteredPayers = [...this.payers];
      this.loadInitialPayers();
      this.cdr.detectChanges();
    }
  }

  getPayer(payerId: string): Payer|null {
    return this.payers.find(p => p.payerId === this.selectedPayerId || p.aliasPayerIds.includes(this.selectedPayerId)) || null
  }

  loadInitialPayers() {
    this.displayedPayers = this.filteredPayers.slice(0, this.pageSize);

    // Ensure selected payer is included
    const selectedPayer = this.getPayer(this.selectedPayerId)
    if (selectedPayer && !this.displayedPayers.some(p => p.payerId === selectedPayer.payerId)) {
      this.displayedPayers.unshift(selectedPayer);
    }
  }

  toggleDropdown(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropdownOpen = !this.dropdownOpen;

    if (this.dropdownOpen) {
      setTimeout(() => this.searchField.nativeElement.focus()) // focus search field
      this.searchTerm = ''; // Reset search when opening dropdown
      this.filteredPayers = [...this.payers]; // Reset to full list
      this.loadInitialPayers();
    }
  }

  onSearchChange() {
    this.filteredPayers = this.payers.filter(payer =>
      payer.payerName.toLowerCase().includes(this.searchTerm.toLowerCase())
    );

    this.currentPage = 0;
    this.loadInitialPayers();
  }

  onDropdownScroll(event: any) {
    const element = event.target;
    const bottomReached = element.scrollTop + element.clientHeight >= element.scrollHeight - 5;

    if (bottomReached) {
      this.loadMorePayers();
    }
  }

  loadMorePayers() {
    if (this.displayedPayers.length < this.filteredPayers.length) {
      this.currentPage++;
      const nextChunk = this.filteredPayers.slice(this.currentPage * this.pageSize, (this.currentPage + 1) * this.pageSize);
      this.displayedPayers = [...this.displayedPayers, ...nextChunk];
    }
  }

  selectPayer(payer: Payer) {
    this.selectedPayerId = payer.payerId;
    this.onChange.emit(payer);
    // Ensure dropdown closes properly
    setTimeout(() => {
      this.dropdownOpen = false;
      this.cdr.detectChanges(); // Force UI update
    }, 0);
  }

  getSelectedPayerName() {
    return this.getPayer(this.selectedPayerId)?.payerName;
  }

  trackByPayerId(_index: number, payer: any): string {
    return payer.payerId;
  }

  // Detect clicks outside the component and close the dropdown
  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
    }
  }
}
