import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AuthUser } from '../models/auth-user';
import { environment } from '../../../environments/environment'

import { Amplify } from 'aws-amplify'
import { signIn, confirmSignIn, fetchAuthSession, AuthSession, signOut, fetchMFAPreference, setUpTOTP, verifyTOTPSetup, updateMFAPreference, resetPassword, confirmResetPassword } from 'aws-amplify/auth'

import { generateQRCode } from "../helpers/authenticator"

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: environment.ClientId,
      userPoolId: environment.UserPoolId
    }
  }
})

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public validatePassword(
    password: string,
    name: string
  ): string|null {
    if (password.length < 10) {
      return `Your ${name} must be at least 10 characters`
    } else if (password.search(/[a-z]/) < 0) {
      return `Your ${name} must contain at least one lowercase letter`
    } else if (password.search(/[A-Z]/) < 0) {
      return `Your ${name} must contain at least one uppercase letter`
    } else if (password.search(/[0-9]/) < 0) {
      return `Your ${name} must contain at least one number`
    } else if (password.search(/[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/) < 0) {
      return `Your ${name} must contain at least one special character`
    }
    return null
  }

  async newPassword(
    email: string,
    oldPassword: string,
    newPassword: string
  ) {
    try {
      await this.login(email, oldPassword)
    } catch (error) {
      if (error == COGNITO_RESPONSES.newPasswordRequired) {
        await confirmSignIn({
          challengeResponse: newPassword
        })

        const preference = await fetchMFAPreference()

        if (preference.enabled == undefined &&
          environment.name != "local") {
          throw COGNITO_RESPONSES.mfaSetup
        }
      } else {
        console.log(error)
        throw error
      }
    }
  }

  async resetPassword(
    email: string
  ) {
    await resetPassword({
      username: email
    })
  }

  async confirmVerificationCode(
    username: string,
    newPassword: string,
    confirmationCode: string
  ) {
    await confirmResetPassword({
      username,
      newPassword,
      confirmationCode
    })
  }

  public logout$ = new Subject<void>();

  async getUserGroups(): Promise<string[]> {
    const session = await fetchAuthSession()

    if (
      !session ||
      !session.tokens ||
      !session.tokens.accessToken ||
      !session.tokens.accessToken.payload ||
      !session.tokens.accessToken.payload["cognito:groups"]
    ) {
      return []
    }

    return session.tokens.accessToken.payload["cognito:groups"] as string[]
  }

  async getUserCognitoId(): Promise<string|null> {
    const session = await fetchAuthSession()

    if (session == null ||
      session == undefined) {
      return null
    }

    return session.userSub || null
  }

  async hasGroup(groupName): Promise<boolean> {
    const groups = await this.getUserGroups()
    return groups.includes(groupName)
  }

  async login(
    email: string,
    password: string
  ) {
    if (
      !email ||
      !password
     ) {
      throw COGNITO_RESPONSES.invalidEmailAddress
    }

    try {
      const res = await signIn({
        username: email,
        password
      })

      if (res.nextStep &&
        res.nextStep.signInStep) {
        if (res.nextStep.signInStep == "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
          throw COGNITO_RESPONSES.mfaRequired
        } else if (res.nextStep.signInStep == "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
          throw COGNITO_RESPONSES.newPasswordRequired
        }
      }

      const preference = await fetchMFAPreference()

      if (preference.enabled == undefined &&
        environment.name != "local") {
        throw COGNITO_RESPONSES.mfaSetup
      }
    } catch (error: any) {
      if (error.message == "User does not exist.") {
        throw COGNITO_RESPONSES.invalidEmailAddress
      } else if (error.message == "Incorrect username or password.") {
        throw COGNITO_RESPONSES.invalidPassword
      } else if (error.message == "There is already a signed in user.") {
        return
      }

      throw error
    }
  }

  async getQRCode(
    email: string
  ) {
    const res = await setUpTOTP()
    const code = await generateQRCode(email, res.sharedSecret)
    return code
  }

  async submitSoftwareToken(
    code: string
  ) {
    if (!code) {
      throw COGNITO_RESPONSES.invalidCodeLength
    }

    try {
      await confirmSignIn({
        challengeResponse: code
      })
    } catch (error: any) {
      if (error.message.includes("UserNotFoundException")) {
        throw COGNITO_RESPONSES.invalidEmailAddress
      } else if (error.message == "Invalid code received for user") {
        throw COGNITO_RESPONSES.invalidCode
      } else if (error.message == "Your software token has already been used once.") {
        throw COGNITO_RESPONSES.invalidCode
      } else if (error.message.includes("ExpiredCodeException")) {
        throw COGNITO_RESPONSES.invalidCode
      }

      console.log({
        message: error.message,
        code: error.code
      })

      throw error
    }
  }

  async verifySoftwareToken(
    code: string
  ) {
    if (!code) {
      throw COGNITO_RESPONSES.invalidCodeLength
    }

    code = code.replace(/\s/g, "")

    try {
      const res = await verifyTOTPSetup({
        code
      })

      await updateMFAPreference({
        totp: "ENABLED"
      })
    } catch (error: any) {
      if (error.message == "1 validation error detected: Value at 'userCode' failed to satisfy constraint: Member must have length greater than or equal to 6") {
        throw COGNITO_RESPONSES.invalidCodeLength
      } else if (error.message == "Code mismatch") {
        throw COGNITO_RESPONSES.invalidCode
      }
      console.log(error.message)
      throw error
    }
  }

  public async getSession(): Promise<AuthSession> {
    const session = await fetchAuthSession()

    if (
      !session ||
      !session.tokens
    ) {
      throw COGNITO_RESPONSES.invalidSession
    }

    return session
  }

  public async getAccessToken(): Promise<string|null> {
    const session = await this.getSession()
    return session.tokens.accessToken.toString()
  }

  async logout() {
    this.logout$.next()

    await signOut()
  }
}

export enum COGNITO_RESPONSES {
  invalidEmailAddress,
  invalidPassword,
  newPasswordRequired,
  mfaRequired,
  mfaSetup,
  invalidSession,
  invalidCodeLength,
  invalidCode
}
