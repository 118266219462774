<div class="flex h-screen hero bg-no-repeat bg-cover" style="background-image: url('assets/background.svg');">

  <div class="w-[60%] h-full flex flex-col">
    <div class="flex-1 flex items-center justify-center">
      <img src="assets/hero.svg" class="max-h-[80vh] h-full w-full"/>
    </div>
    <div class="p-8 max-h-[20vh]">
      <img src="assets/testimonials.svg" class="w-full mx-auto"/>
    </div>
  </div>

  <div class="w-[40%] h-full p-2">
    <div class="w-full h-full p-8 bg-white rounded-lg flex flex-col items-center justify-center">
      <img class="h-[40px] mb-16" src="assets/logo-black.svg" />
      <img class="h-[240px] mb-16" [src]="code" />
      <form class="w-full max-w-md" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-control mb-4">
          <input
            type="text"
            formControlName="code"
            placeholder="Enter the generated code"
            required
            autofocus
            class="input input-bordered w-full bg-white" />
        </div>
        <button
          type="submit"
          class="btn btn-accent text-white w-full mt-4">Continue</button>
        <div class="text-center mt-4">
          <a [routerLink]="['/login']" class="text-gray-600">Back to Sign In</a>
        </div>
      </form>
    </div>
  </div>
</div>
