<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
  <h1 class="text-xl font-bold">Add Provider</h1>
</div>
<div class="mt-[60px] p-6">
	<div class="max-w-[50%] min-w-[500px] mx-auto bg-white border rounded-lg p-6">
		<form [formGroup]="form" (ngSubmit)="onSubmit(form)">

			<label class="form-control w-full p-2">
				<div class="label">
					<span class="label-text">Payer</span>
				</div>
				<app-select-payer
					[payers]="payers"
					[selectedPayerId]="form.get('payerId')?.value"
					(onChange)="onPayerSelected($event)"
					[class]="'h-full'"
				/>
			</label>

			<label class="form-control w-full p-2">
				<div class="label">
					<span class="label-text">State</span>
				</div>
				<select class="select select-bordered bg-white"
					formControlName="state" required>
					<option selected value="">Select a State</option>
					<option *ngFor="let state of states" [value]="state">{{ state }}</option>
				</select>
			</label>

			<label class="form-control w-full p-2">
				<div class="label">
					<span class="label-text">NPI</span>
				</div>
				<input type="text" class="input input-bordered w-full bg-white"
					type="text"
					formControlName="npi" required
				/>
			</label>

			<button class="btn btn-accent text-white m-2 min-w-[180px]" type="submit" [disabled]="form.invalid">
				<span *ngIf="isSubmitting == false">Add Provider</span>
				<span *ngIf="isSubmitting == true" class="loading loading-spinner loading-sm text-white h-4 w-4 m-auto"></span>
			</button>

		</form>
	</div>
</div>