import { Component, Input } from '@angular/core'
import { NetworkRecordsService } from '../@shared/services/network-records.service'
import { NetworkStatusEvent, NetworkStatusRecord, NetworkStatusRecordFilters, NetworkStatusRecordFiltersValues, NetworkStatusRecordStatus } from '../@shared/types/network-records'
import { Payer } from '../@shared/models/payer'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-network-records',
  templateUrl: './network-records.component.html',
  styles: [`
    img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
    }
    .pointer {
      cursor: pointer;
    }
  `]
})
export class NetworkRecordsComponent {
  @Input()
  payers: Payer[]
  @Input()
  inputFilters: Omit<NetworkStatusRecordFilters, "offset"> = {}

  allFilters = [
    {
      name: 'status',
      values: 'Statuses'
    },
    {
      name: 'payerId',
      values: 'Payers'
    },
    {
      name: 'state',
      values: 'States'
    },
    {
      name: 'networkType',
      values: 'Insurance Type Codes'
    }
  ]

  records: NetworkStatusRecord[] = []
  eventsById: { [id: number]: NetworkStatusEvent[] } = {}
  filterValues = {}
  selectedFilters: Omit<NetworkStatusRecordFilters, "offset"> = {}

  statusValues = Object.values(NetworkStatusRecordStatus)
  totalRecords: number = 0
  loading = false
  currentPage = 1
  limit = 100

  constructor(
    private networkRecordsService: NetworkRecordsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.loading = true
    const queryParams: any = this.route.snapshot.queryParams

    if (queryParams) {
      this.selectedFilters = {
        status: queryParams.status ?? this.inputFilters.status ?? "pending",
        payerId: queryParams.payerId ?? this.inputFilters.payerId ?? undefined,
        state: queryParams.state ?? this.inputFilters.state ?? undefined,
        networkType: queryParams.networkType ?? this.inputFilters.networkType ?? undefined
      }
      this.cleanSelectedFilters()
    }

    const filterValues = await this.networkRecordsService.getNetworkRecordsFiltersValues()

    this.filterValues = {
      "Insurance Type Codes": filterValues.networkTypes.map(val => ({ option: val, value: val})).sort((a, b) => (a.option || "").localeCompare(b.option || "")),
      States: filterValues.states.map(val => ({ option: val, value: val})).sort((a, b) => (a.option || "").localeCompare(b.option || "")),
      Payers: filterValues.payerIds.map(val => ({ option: this.getPayerName(val), value: val})).sort((a, b) => (a.option || "").localeCompare(b.option || "")),
      Statuses: this.statusValues.map(val => ({ option: val, value: val}))
    }

    this.loading = false
    await this.getRecords(1)
  }

  async getRecords(page: number) {
    if (this.loading) {
      return
    }
    this.loading = true
    this.records = []
    const { records, total } = await this.networkRecordsService.getNetworkRecords({ offset: (page - 1) * this.limit, ...this.selectedFilters })

    this.records = records
    this.totalRecords = total
    this.currentPage = page
    this.loading = false
  }

  private getPayerName(payerId: string) {
    return this.payers.find((payer: Payer) => payer.payerId === payerId || payer.internalPayerId === payerId || payer.aliasPayerIds.includes(payerId))?.payerName
  }

  async toggleEventDetails(record: NetworkStatusRecord) {
    this.loading = true
    if (!this.eventsById[record.id]) {
      const events = await this.networkRecordsService.getNetworkStatusEventsForRecord(`${record.id}`)
      this.eventsById[record.id] = events
    }
    record.isExpanded = !record.isExpanded
    this.loading = false
  }

  async didSelectFilter() {
    this.loading = true
    this.cleanSelectedFilters()
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { ...this.selectedFilters },
      fragment: this.route.snapshot.fragment
    })
    this.records = []
    this.loading = false
    await this.getRecords(1)
  }

  private cleanSelectedFilters() {
    this.selectedFilters = Object.fromEntries(
      Object.entries(this.selectedFilters).filter(([_, value]) => value !== undefined)
    )
  }

  async didSelectStatus(record: NetworkStatusRecord) {
    record.isLoading = true
    await this.networkRecordsService.updateNetworkStatusRecordStatuses(record.status as NetworkStatusRecordStatus, [record.id])
    this.records = []
    record.isLoading = false
    await this.getRecords(1)
  }

  async didClickUpdateNetworkPayerId(event: Event, record: NetworkStatusRecord, networkPayerId: string|null) {
    const element = event.target as HTMLElement

    const dropdown = element.closest("ul") as HTMLElement

    dropdown?.blur()

    if (networkPayerId == null) {
      return
    }

    record.isLoading = true
    await this.networkRecordsService.updateNetworkStatusRecordNetworkPayerId(record.id, networkPayerId)
    this.records = []
    record.isLoading = false
    await this.getRecords(1)
  }

  didClickSelectAll() {
    const selectedValue = this.areAllSelected() ? false : true
    this.records = this.records.map((record: NetworkStatusRecord) => ({
      ...record,
      isSelected: selectedValue
    }))
  }

  areAllSelected() {
    return this.records.length > 0 && this.records.length == this.getAmountOfSelectedRecords()
  }

  getAmountOfSelectedRecords() {
    return this.records.filter((record: NetworkStatusRecord) => record.isSelected).length
  }

  async didChangeStatusSelection(event: Event) {
    const status = (event.target as HTMLSelectElement).value
    const recordsIds = this.records.filter((record: NetworkStatusRecord) => record.isSelected).map((record: NetworkStatusRecord) => record.id)
    await this.networkRecordsService.updateNetworkStatusRecordStatuses(status as NetworkStatusRecordStatus, recordsIds)
    await this.getRecords(1)
  }
}
