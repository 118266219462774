import { Component, OnInit } from '@angular/core';
import { VERIFICATION_STATUS, VerificationService } from "../@shared/services/verification.service"
import { StatService } from '../@shared/services/stat.service'
import { Chart, registerables } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { Papa } from 'ngx-papaparse'
import { Router } from '@angular/router';
import { getPages } from "../@shared/helpers/pages"

Chart.register(...registerables)

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {

  verifications: any[] = []
  searchValue: string = ""
  status: keyof typeof VERIFICATION_STATUS = 'All Statuses'
  statuses = Object.keys(VERIFICATION_STATUS)
  loading = false
  pages: number[] = []
  currentPage = 1

  constructor(
    private statService: StatService,
    private papa: Papa,
    private verificationService: VerificationService,
    private router: Router
  ) {}

  public chartOne: any;

  verificationCount = 0
  discoveryCount = 0
  labels: Date[] = []
  verificationFacts: number[] = []
  discoveryFacts: number[] = []

  async ngOnInit() {
    const stats = await this.statService.getStats()

    const timestampValues: {
      [timestamp: string]: {
        verificationCount: number,
        discoveryCount: number
      }
    } = {}

    for (const fact of stats.verificationFacts) {
      if (timestampValues[fact.timestamp] == undefined) {
        timestampValues[fact.timestamp] = {
          verificationCount: 0,
          discoveryCount: 0
        }
      }

      timestampValues[fact.timestamp].verificationCount = fact.count
    }

    for (const fact of stats.discoveryFacts) {
      if (timestampValues[fact.timestamp] == undefined) {
        timestampValues[fact.timestamp] = {
          verificationCount: 0,
          discoveryCount: 0
        }
      }

      timestampValues[fact.timestamp].discoveryCount = fact.count
    }

    let facts: {
      date: Date,
      verificationCount: number,
      discoveryCount: number
    }[] = []

    for (const timestamp in timestampValues) {
      facts.push({
        date: new Date(timestamp),
        verificationCount: timestampValues[timestamp].verificationCount,
        discoveryCount: timestampValues[timestamp].discoveryCount
      })
    }

    facts = facts.sort((a, b) => a.date.getTime() - b.date.getTime())

    this.verificationCount = stats.verificationCount
    this.discoveryCount = stats.discoveryCount
    this.labels = facts.map((fact) => fact.date)
    this.verificationFacts = facts.map((fact) => fact.verificationCount)
    this.discoveryFacts = facts.map((fact) => fact.discoveryCount)

    Chart.defaults.font.family = "Inter"
    Chart.defaults.plugins.legend.display = false

    this.chartOne = this.getChart("chartOne", this.labels, [
      {
        label: "Verification",
        facts: this.verificationFacts,
        color: "rgba(193, 219, 161, 1)"
      },
      {
        label: "Discovery",
        facts: this.discoveryFacts,
        color: "#FEC371"
      }
    ])

    await this.getVerifications(1)
  }

  async getVerifications(page: number) {
    if (this.loading) {
      return
    }

    this.currentPage = page

    this.verifications = []
    this.loading = true

    const res = await this.verificationService.getVerifications(
      false,
      {
        query: this.searchValue,
        status: VERIFICATION_STATUS[this.status]
      },
      (page - 1) * 20
    )

    this.verifications = this.verifications.concat(res.verifications)
    this.loading = false

    this.pages = getPages(res.total, 20, page)
  }

  getChart(
    id: string,
    labels: Date[],
    data: {
      label: string,
      facts: number[],
      color: string
    }[]
  ) {
    return new Chart(id, {
      type: 'bar',
      data: {
        labels,
        datasets: data.map((dataset) => {
          return {
            label: dataset.label,
            data: dataset.facts,
            backgroundColor: dataset.color,
            pointStyle: false,
            barThickness: 8,
            borderWidth: 1,
            borderColor: 'white'
          }
        })
      },
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                const value = context.raw || 0;
                return `${context.dataset.label}: ${value}`;
              }
            }
          }
        },
        layout: {
          padding: {
            top: 24
          }
        },
        scales: {
          x: {
            type: 'time', // Use time scale
            time: {
              unit: 'day', // Adjust granularity: 'day', 'month', 'year', etc.
              tooltipFormat: 'MMM d, yyyy', // Tooltip format
              displayFormats: {
                day: 'MMM d' // Display format for labels
              }
            },
            ticks: {
              color: 'rgba(20, 25, 31, 0.3)',
              autoSkip: false
            },
            grid: {
              display: false
            },
            border: {
              display: false
            }
          },
          y: {
            beginAtZero: true,
            position: 'right',
            ticks: {
              color: 'rgba(20, 25, 31, 0.3)',
              autoSkip: false
            },
            grid: {
              display: false
            },
            border: {
              display: false
            }
          }
        }
      }
    })
  }

  didClickDownload() {
    if (!this.verifications.length) {
      return
    }
    const rows: any = this.verifications.map((row: any) => {
      const { verificationId, status, memberId, firstName, lastName, payerName, created } = row
      const createdDate = new Date(created)
      return {
        verificationId,
        status,
        memberId,
        firstName,
        lastName,
        payerName,
        // format created date to eq 'Dec 13, 2024'
        created: createdDate.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })
      }
    })
    const csv = "data:text/csv;charset=utf-8," + this.papa.unparse(rows)
    const encodedUri = encodeURI(csv)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "sohar_verifications.csv")
    document.body.appendChild(link)
    link.click()
  }

  goToDetails(verificationId: string) {
    this.router.navigate(['/verifications', verificationId]);
  }

  didSelectFilter() {
    this.currentPage = 1
    this.verifications = []
    this.getVerifications(1)
  }

  timeout = null

  didChangeSearchValue() {
    window.clearTimeout(this.timeout)

    this.timeout = window.setTimeout(() => {
      this.verifications = []
      this.getVerifications(1)
    }, 500)
  }
}

