<div class="flex justify-between items-center mb-4">
	<div></div>
	<div class="flex gap-4">
		<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" [routerLink]="['/create-rule']">
			<img class="max-h-[16px] max-w-[16px]" src="assets/network-status.svg" />
			Create Rule
		</a>
	</div>
</div>

<div class="overflow-x-auto">
	<table class="table w-full">
		<thead class="bg-gray-200 rounded-lg">
			<tr class="rounded-lg border-b-0">
				<th class="rounded-l-lg">Rule</th>
				<th>Status</th>
				<th>Conditions</th>
				<th></th>
				<th></th>
				<th class="rounded-r-lg"></th>
			</tr>
		</thead>
		<tbody *ngIf="loading">
			<tr class="border-b-0">
				<td><div class="skeleton h-6 w-full"></div></td>
				<td><div class="skeleton h-6 w-full"></div></td>
				<td colspan="2"><div class="skeleton h-6 w-full"></div></td>
				<td><div class="skeleton h-6 w-full"></div></td>
				<td><div class="skeleton h-6 w-full"></div></td>
			</tr>
		</tbody>
		<tbody *ngFor="let rule of rules">
			<tr>
				<td [innerHTML]="rule.displayName"></td>
				<td>
					<select class="select select-bordered select-sm w-full max-w-xs bg-white" [(ngModel)]="rule.status" (change)="didSelectStatus(rule)">
						<option *ngFor="let status of statusValues" [value]="status">{{ status }}</option>
					</select>
				</td>
				<td colspan="2"></td>
				<td>
					<button class="btn btn-sm bg-warning text-white flex items-center gap-2" (click)="deleteRule($event, rule)"><svg class="h-[12px] text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="_01_align_center" data-name="01 align center"><path fill="currentColor" d="M22,4H17V2a2,2,0,0,0-2-2H9A2,2,0,0,0,7,2V4H2V6H4V21a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V6h2ZM9,2h6V4H9Zm9,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V6H18Z"/><rect fill="currentColor" x="9" y="10" width="2" height="8"/><rect fill="currentColor" x="13" y="10" width="2" height="8"/></g></svg>Delete</button>
				</td>
				<td>
					<button class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="submitConditionChanges($event, rule)" [disabled]="!isInputValid(rule)">Save</button>
				</td>
			</tr>
			<tr *ngFor="let condition of rule.conditions" class="bg-gray-100">
				<td colspan="2"></td>
				<td>
					<select class="select select-bordered select-sm w-full max-w-xs bg-white" [(ngModel)]="condition.key">
						<option *ngFor="let key of keyValues" [value]="key.value">{{ key.displayName }}</option>
					</select>
				</td>
				<td>
					<select class="select select-bordered select-sm w-full max-w-xs bg-white" *ngIf="condition.key == 'payerId' || condition.key == 'relatedEntityPayerId'" [(ngModel)]="condition.value">
						<option *ngFor="let payer of payerValues" [value]="payer.payerId">{{ payer.payerName }}</option>
					</select>
					<select class="select select-bordered select-sm w-full max-w-xs bg-white" *ngIf="condition.key == 'state'" [(ngModel)]="condition.value">
						<option *ngFor="let state of states" [value]="state">{{ state }}</option>
					</select>
					<input class="input input-bordered input-sm w-full max-w-xs bg-white" *ngIf="condition.key == 'networkType'" type="text" [(ngModel)]="condition.value" />
				</td>
				<td></td>
				<td>
					<button class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="removeCondition(rule, condition)" *ngIf="rule.conditions.length > 1"><svg class="h-[12px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="_01_align_center" data-name="01 align center"><path d="M22,4H17V2a2,2,0,0,0-2-2H9A2,2,0,0,0,7,2V4H2V6H4V21a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V6h2ZM9,2h6V4H9Zm9,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V6H18Z"/><rect x="9" y="10" width="2" height="8"/><rect x="13" y="10" width="2" height="8"/></g></svg>Remove</button>
				</td>
			</tr>
			<tr *ngIf="rule.conditions.length < 4" class="bg-gray-100">
				<td colspan="6">
					<button class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="addCondition(rule)"><svg class="h-[16px]" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path d="M17,11H13V7a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v4H7a1,1,0,0,0-1,1H6a1,1,0,0,0,1,1h4v4a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13h4a1,1,0,0,0,1-1h0A1,1,0,0,0,17,11Z"/></svg>Add Condition</button>
				</td>
			</tr>
		</tbody>
	</table>

	<div class="flex justify-between items-center mb-4 mt-6">
		<div class="join">
			<button class="join-item btn" [class.btn-active]="page == currentPage" (click)="getRules(page)" *ngFor="let page of pages">{{ page }}</button>
		</div>
		<span class="text-sm text-gray-600">Showing {{ rules.length | number }} of {{ totalRecords | number }}</span>
	</div>
</div>
