import { Component, Output, Input, EventEmitter } from '@angular/core'
import { NetworkRecordsService } from '../../services/network-records.service'
import { NetworkStatusFullRule, NetworkStatusRecord, NetworkStatusRecordStatus, NetworkStatusRuleCondition } from '../../types/network-records'
import { ALL_STATES } from '../../const/states'
import { NETWORK_TYPES } from '../../const/networkTypes'
import { Payer } from '../../models/payer'

@Component({
  selector: 'app-create-rule',
  templateUrl: './create-rule.component.html'
})
export class CreateRuleComponent {

  @Output() close = new EventEmitter<boolean>()

  @Input() set rule(value: NetworkStatusFullRule|null) {
    if (value === null) {
      this.id = null
      this.name = null
      this.status = NetworkStatusRecordStatus.InNetwork
      this.conditions = []
      return
    }

    this.id = value.id
    this.name = value.name || `Rule ${value.id}`
    this.status = value.status
    this.conditions = value.conditions
  }

  @Input() payers: Payer[]

  states: string[] = ALL_STATES
  networkTypes: string[] = NETWORK_TYPES

  keyValues: { value: keyof NetworkStatusRecord, displayName: string}[] = [
    { value: 'payerId', displayName: 'Payer' },
    { value: 'state', displayName: 'State' },
    { value: 'networkType', displayName: 'Insurance Type Code' },
    { value: 'relatedEntityPayerId', displayName: 'Related Entity Payer' },
  ]

  id: number|null = null
  name: string|null = null
  status: NetworkStatusRecordStatus = NetworkStatusRecordStatus.InNetwork
  private conditions: NetworkStatusRuleCondition[] = []

  get conditionKeys(): string[] {
    return Array.from(new Set(this.conditions.map((condition) => condition.key)))
  }

  get groupedConditions(): {
    [key: string]: NetworkStatusRuleCondition[]
  } {
    return this.conditions.reduce((acc, item) => {
      if (!acc[item.key]) {
        acc[item.key] = []
      }

      acc[item.key].push(item)

      return acc
    }, {})
  }

  get numberOfConditions(): number {
    return Object.keys(this.groupedConditions).length
  }

  constructor(
    private networkRecordsService: NetworkRecordsService,
  ) {}

  async didClickSaveRule() {
    const isDuplicatedConditionsKey = this.conditions.some((condition, index) => {
      return this.conditions.some((otherCondition, otherIndex) => {
        return index !== otherIndex && condition.key === otherCondition.key && condition.value === otherCondition.value
      })
    })

    if (isDuplicatedConditionsKey) {
      alert("Please remove duplicated conditions")
      return
    }

    if (!this.isInputValid()) {
      alert("Please fill all conditions")
      return
    }

    if (this.id === null) {
      await this.networkRecordsService.createNetworkRule(this.status, this.name, this.conditions)
    } else {
      await this.networkRecordsService.updateNetworkRule(this.id, this.status, this.name, this.conditions)
    }

    this.close.emit(true)
  }

  didClickCloseModal() {
    this.close.emit(false)
  }

  isInputValid() {
    return this.status != null && this.name != null && this.conditions.length > 0 && this.conditions.every(
      condition => condition.key != null && condition.value != null && condition.value != '' && condition.value != undefined
    )
  }

  async didClickDeleteRule() {
    if (confirm(`Are you sure you want to delete this rule?`)) {
      await this.networkRecordsService.deleteNetworkRule(this.id)
      this.close.emit(true)
    }
  }

  private getNotSelectedConditions() {
    return this.keyValues.filter(keyValue => !this.conditions.some(condition => condition.key === keyValue.value))
  }

  addCondition() {
    if (this.numberOfConditions >= 4) {
      return;
    }

    this.conditions.push({ key: this.getNotSelectedConditions()[0].value, value: undefined, id: 0, ruleId: 0 })
  }

  addGroupedCondition(key: string) {
    this.conditions.push({ key, value: undefined, id: 0, ruleId: 0 })
  }

  removeCondition(condition: Pick<NetworkStatusRuleCondition, 'key' | 'value'>) {
    this.conditions = this.conditions.filter((_condition) => {
      if (condition.key === _condition.key && condition.value === _condition.value) {
        return false
      }

      return true
    })
  }

  onPayerSelected(selectedPayer: Payer, condition: NetworkStatusRuleCondition) {
    condition.value = selectedPayer.internalPayerId
  }
}
