import { Component } from '@angular/core'
import { NetworkStatusService } from "../@shared/services/network-status.service"
import { Router } from '@angular/router'
import { NetworkStatusObject } from "../@shared/models/networkStatusObject"

@Component({
  selector: 'app-network-status',
  templateUrl: './network-status.component.html'
})
export class NetworkStatusComponent {

  constructor(
    private networkStatusService: NetworkStatusService,
    private router: Router
  ) {}

  rows: NetworkStatusObject[] = []
  providers: string[] = []
  selectedProvider: string|null = null

  get selectedPlans(): NetworkStatusObject[] {
    const _selectedPlans: NetworkStatusObject[] = []

    for (const payer of this.rows) {
      for (const state of payer.items) {
        for (const network of state.items) {
          for (const plan of network.items) {
            if (plan._isSelected) {
              _selectedPlans.push(plan)
            }
          }
        }
      }
    }

    return _selectedPlans
  }

  async ngOnInit() {
    const rows = await this.networkStatusService.getRows()

    const map = {}

    for (const row of rows) {

      if (!this.providers.includes(row.npi)) {
        this.providers.push(row.npi)
      }

      if (map[row.payerName] == undefined) {
        map[row.payerName] = {}
      }

      if (map[row.payerName][row.state] == undefined) {
        map[row.payerName][row.state] = {}
      }

      if (map[row.payerName][row.state][row.insuranceTypeCode] == undefined) {
        map[row.payerName][row.state][row.insuranceTypeCode] = []
      }

      map[row.payerName][row.state][row.insuranceTypeCode].push(row)
    }

    for (const _payerName in map) {
      const payer = new NetworkStatusObject(_payerName)

      for (const _state in map[_payerName]) {
        const state = new NetworkStatusObject(_state)

        for (const _insuranceTypeCode in map[_payerName][_state]) {
          const insuranceTypeCode = new NetworkStatusObject(_insuranceTypeCode)

          for (const plan of map[_payerName][_state][_insuranceTypeCode]) {
            insuranceTypeCode._items.push(new NetworkStatusObject(plan.planName, true, plan.networkStatus))
          }

          state._items.push(insuranceTypeCode)
        }

        payer._items.push(state)
      }

      this.rows.push(payer)
    }

    this.rows = this.rows.sort((a, b) => {
      return a.label.localeCompare(b.label)
    })
  }

  didChangeNetworkStatusSelection(event: any) {

    const value = event.target.value

    if (value == null ||
      value == undefined ||
      value == "") {
      return
    }

    for (const payer of this.rows) {
      for (const state of payer.items) {
        for (const network of state.items) {
          for (const plan of network.items) {
            if (plan._isSelected) {
              plan.networkStatus = value
              plan._isSelected = false
            }
          }
        }
      }
    }

    event.target.value = ""
  }

  didClickSelect(item: any) {
    let plans: any[] = []

    if (item.states != undefined) {
      for (const state of item.states) {
        for (const network of state.networks) {
          plans = plans.concat(network.plans)
        }
      }
    } else if (item.networks != undefined) {
      for (const network of item.networks) {
        plans = plans.concat(network.plans)
      }
    } else if (item.plans != undefined) {
      plans = item.plans
    } else {
      plans = [item]
    }

    for (const plan of plans) {
      plan.isSelected = true
    }
  }
}
