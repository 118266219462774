<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
  <h1 class="text-xl font-bold">Payers</h1>
</div>
<div class="p-6 bg-white min-h-screen">
  <div class="flex justify-between items-center mb-4">
  	<div class="flex gap-4">
    <input type="text" [(ngModel)]="searchValue" placeholder="Search payers..." class="input input-bordered w-64 bg-white" />
  </div>
    <button class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="didClickDownload()">
    <svg class="h-[12px]" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path d="M9.878,18.122a3,3,0,0,0,4.244,0l3.211-3.211A1,1,0,0,0,15.919,13.5l-2.926,2.927L13,1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1l-.009,15.408L8.081,13.5a1,1,0,0,0-1.414,1.415Z"/><path d="M23,16h0a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17A1,1,0,0,0,23,16Z"/></svg>
    Download CSV
  </button>
  </div>
  <div class="overflow-x-auto">
    <table class="table w-full">
      <thead class="bg-gray-200 rounded-lg">
        <tr class="rounded-lg border-b-0">
          <th class="rounded-l-lg">Name</th>
          <th class="rounded-r-lg">Payer ID</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="loading" class="border-b-0">
          <td><div class="skeleton h-6 w-full"></div></td>
          <td><div class="skeleton h-6 w-full"></div></td>
        </tr>
        <tr *ngFor="let payer of filteredPayers">
          <td>{{ payer.payerName }}</td>
          <td>{{ payer.payerId }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
