import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { getPages } from '../../helpers/pages';

@Component({
  selector: 'app-page-selector',
  templateUrl: './page-selector.component.html'
})
export class PageSelectorComponent implements OnInit, OnChanges {

  @Input() recordsLength: number;
  @Input() totalLength: number;
  @Input() limit = 100
  @Input() page?: number
  @Output() onPageClick = new EventEmitter<number>();

  pages: number[] = []
  currentPage = 1;

  constructor() { }

  ngOnInit() {
    this.pages = getPages(this.totalLength, this.limit, this.currentPage)
    this.currentPage = this.page ?? this.currentPage
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.currentPage = this.page ?? this.currentPage
    this.pages = getPages(this.totalLength, this.limit, this.currentPage)
  }

  pageClicked(page: number) {
    this.onPageClick.emit(page)
    this.currentPage = page
  }

  getStartIndex() {
    return this.totalLength > 0 ? (this.currentPage - 1) * this.limit + 1 : 0
  }

  getEndIndex() {
    const calcEndIndex = (this.currentPage - 1) * this.limit + this.recordsLength
    return Math.min(calcEndIndex, this.totalLength)
  }
}
