<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
  <h1 class="text-xl font-bold">Providers</h1>
</div>

<div class="p-6 border rounded-lg m-6 bg-white">
  <div class="flex justify-between items-center mb-4">
    <div class="flex gap-4">
      <select class="select select-bordered bg-white" *ngFor="let filter of allFilters"[(ngModel)]="selectedFilters[filter.name]" (change)="didSelectFilter()">
        <option [ngValue]="undefined">All {{filter.values}}</option>
        <option *ngFor="let value of filterValues[filter.values]" value="{{value.value}}">
          {{ value.option }}
        </option>
      </select>
    </div>
    <div class="flex gap-4">
      <a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" [routerLink]="['/create-provider-record']">
        <svg class="h-[14px]" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 512 512" xml:space="preserve"><g><path d="M480,224H288V32c0-17.673-14.327-32-32-32s-32,14.327-32,32v192H32c-17.673,0-32,14.327-32,32s14.327,32,32,32h192v192   c0,17.673,14.327,32,32,32s32-14.327,32-32V288h192c17.673,0,32-14.327,32-32S497.673,224,480,224z"/></g></svg>
        Add Provider
      </a>
    </div>
  </div>

  <div class="overflow-x-auto">
    <table class="table w-full">
      <thead class="bg-gray-200 rounded-lg">
        <tr class="rounded-lg border-b-0">
          <th class="rounded-l-lg">Payer</th>
          <th>State</th>
          <th>NPI</th>
          <th>Provider</th>
          <th>Type</th>
          <th class="rounded-r-lg"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="loading" class="border-b-0">
          <td><div class="skeleton h-6 w-full"></div></td>
          <td><div class="skeleton h-6 w-full"></div></td>
          <td><div class="skeleton h-6 w-full"></div></td>
          <td><div class="skeleton h-6 w-full"></div></td>
          <td><div class="skeleton h-6 w-full"></div></td>
          <td><div class="skeleton h-6 w-full"></div></td>
        </tr>
        <tr *ngFor="let record of records">
          <td>{{ record.payerName }}</td>
          <td>{{ record.state }}</td>
          <td>{{ record.npi }}</td>
          <td>{{ record.providerName }}</td>
          <td>{{ record.providerType }}</td>
          <td>
            <button class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="deleteRecord(record.id)"><svg class="h-[12px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="_01_align_center" data-name="01 align center"><path fill="currentColor" d="M22,4H17V2a2,2,0,0,0-2-2H9A2,2,0,0,0,7,2V4H2V6H4V21a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V6h2ZM9,2h6V4H9Zm9,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V6H18Z"/><rect fill="currentColor" x="9" y="10" width="2" height="8"/><rect fill="currentColor" x="13" y="10" width="2" height="8"/></g></svg>Delete</button>
          </td>
        </tr>
      </tbody>
    </table>

    <app-page-selector
      [recordsLength]="records.length"
      [totalLength]="totalRecords"
      [limit]="limit"
      [page]="currentPage"
      (onPageClick)="getRecords($event)"
    />
  </div>

</div>