import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { Verification } from '../models/verification'
import { map } from 'rxjs/operators'

export const VERIFICATION_STATUS = {
  'All Statuses': null,
  'complete.eligible': 'complete.eligible',
  'complete.ineligible': 'complete.ineligible',
  error: 'error',
  pending: 'pending'
}

type VerificationFilters = {
  query: string | null,
  status?: typeof VERIFICATION_STATUS
}

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  constructor(
    private networkService: NetworkService
  ) {}

  async getVerifications(
    sandbox: boolean,
    filters: VerificationFilters,
    offset: number,
  ): Promise<{
    verifications: Verification[],
    total: number
  }> {
    return new Promise((resolve, reject) => {
      let url = `verifications?sandbox=${sandbox}&offset=${offset}&`
      url += Object.entries(filters).filter(([_, value]) => value != null && value != "")
        .map(([key, value]) => { return `${key}=${value}`})
        .join('&')
      this.networkService.get(url).pipe(
        map((response: any) => {
          return response
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getVerification(verificationId: string): Promise<Verification> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`verifications/${verificationId}`).pipe(
        map((response: any) => {
          return Verification.fromJson(response.verification)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createVerification(
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    state: string,
    memberId: string,
    payerId: string,
    placeOfServiceCode: string,
    specialtyCode: string,
    sandbox: boolean
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`verifications`, {
        firstName,
        lastName,
        dateOfBirth,
        state,
        memberId,
        payerId,
        placeOfServiceCode,
        specialtyCode,
        sandbox
      }).pipe(
        map((response: any) => {
          return response.verificationId
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }
}
