export const getPages = (total: number, limit: number, page: number): number[] => {
	const totalPages = Math.ceil(total / limit)

	if (totalPages <= 11) {
		return Array.from({ length: totalPages }, (_, i) => i + 1)
	}

	const res: number[] = []

	const min = Math.max(1, Math.ceil(page - (11 / 2)))

	for (let i = min; i < totalPages; i++) {
		res.push(i)

		if (res.length == 11) {
			break
		}
	}

	return res
}
