<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
  <h1 class="text-xl font-bold">Dashboard</h1>
  <a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" [routerLink]="['/create-verification']">
    <img class="h-[14px]" src="assets/verification.svg" />
    Create Verification
  </a>
</div>

<div class="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
  <div class="stats border bg-white">
    <div class="stat">
      <div class="stat-title">Total Verification Requests</div>
      <div class="stat-value mt-4">{{ verificationCount | number }}</div>
    </div>
  </div>
  <div class="stats border bg-white">
    <div class="stat">
      <div class="stat-title">Total Discovery Requests</div>
      <div class="stat-value mt-4">{{ discoveryCount | number }}</div>
    </div>
  </div>
</div>

<div class="p-6 border rounded-lg w-[calc(100%-3rem)] mx-auto bg-white">
  <canvas id="chartOne" height="80"></canvas>
  <div class="mt-6 flex">
    <div class="flex items-center mr-6">
      <img class="h-[14px] mr-2" src="assets/verification.svg" />
      <span class="text-sm text-gray-400">Verification</span>
    </div>
    <div class="flex items-center">
      <img class="h-[14px] mr-2" src="assets/discovery.svg" />
      <span class="text-sm text-gray-400">Discovery</span>
    </div>
  </div>
</div>

<div class="p-6 border rounded-lg m-6 bg-white">
  <div class="flex justify-between items-center mb-4">
    <div class="flex gap-4">
      <select class="select select-bordered bg-white" [(ngModel)]="status" (change)="didSelectFilter()">
        <option *ngFor="let status of statuses" [value]="status">{{ status }}</option>
      </select>

      <input type="text" [(ngModel)]="searchValue" (ngModelChange)="didChangeSearchValue()" placeholder="Search verifications..." class="input input-bordered w-64 bg-white" />
    </div>
    <button class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="didClickDownload()">
      <svg class="h-[12px]" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path d="M9.878,18.122a3,3,0,0,0,4.244,0l3.211-3.211A1,1,0,0,0,15.919,13.5l-2.926,2.927L13,1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1l-.009,15.408L8.081,13.5a1,1,0,0,0-1.414,1.415Z"/><path d="M23,16h0a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17A1,1,0,0,0,23,16Z"/></svg>
      Download CSV
    </button>
  </div>

  <div class="overflow-x-auto">
    <table class="table w-full min-h-940">
      <thead class="bg-gray-200 rounded-lg">
        <tr class="rounded-lg border-b-0">
          <th class="rounded-l-lg">Member</th>
          <th>Member ID</th>
          <th>Payer</th>
          <th>Status</th>
          <th class="rounded-r-lg">Created</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="loading" class="border-b-0">
          <td><div class="skeleton h-6 w-full"></div></td>
          <td><div class="skeleton h-6 w-full"></div></td>
          <td><div class="skeleton h-6 w-full"></div></td>
          <td><div class="skeleton h-6 w-full"></div></td>
          <td><div class="skeleton h-6 w-full"></div></td>
        </tr>
        <tr *ngFor="let verification of verifications" (click)="goToDetails(verification.verificationId)"
      class="cursor-pointer hover:bg-gray-100 transition">
          <td>{{ verification.firstName }} {{ verification.lastName }}</td>
          <td>{{ verification.memberId }}</td>
          <td>{{ verification.payerName }}</td>
          <td><span
          class="badge badge-outline text-sm py-3"
          [ngClass]="{
          'badge-success': verification.status == 'complete.eligible',
          'badge-neutral': verification.status == 'complete.ineligible',
          'badge-warning': verification.status.startsWith('pending'),
          'badge-error': verification.status.startsWith('error')
          }">
          {{ verification.status }}
          </span></td>
          <td>{{ verification.created | date: 'MMM d, y h:mm a' }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-page-selector
    [recordsLength]="verifications.length"
    [totalLength]="totalRecords"
    [limit]="limit"
    [page]="currentPage"
    (onPageClick)="getVerifications($event)"
  />
</div>