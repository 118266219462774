export class CarveOut {

	constructor(
		public payerId: string,
		public payerName: string,
		public memberId: string|null,
		public entityIdentifierCode: string|null
	) {}

	static fromJson(data: any|null): CarveOut|null {
		if (!data) {
			return null
		}

		return new CarveOut(
			data.payerId,
			data.payerName,
			data.memberId,
			data.entityIdentifierCode
		)
	}
}
