import { Fact } from "./fact"

export class Stat {

	constructor(
		public verificationCount: number,
		public discoveryCount: number,
		public verificationFacts: Fact[],
		public discoveryFacts: Fact[]
	) {}

	static fromJson(data: any): Stat {
		return new Stat(
			data.verificationCount || 0,
			data.discoveryCount || 0,
			data.verificationFacts.map(fact => Fact.fromJson(fact)),
			data.discoveryFacts.map(fact => Fact.fromJson(fact))
		)
	}
}
