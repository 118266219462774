<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
  <h1 class="text-xl font-bold">Create Verification</h1>
  <a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" [routerLink]="['/create-verifications']">
    <svg class="h-[14px]" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
    Upload CSV
  </a>
</div>

<div class="mt-[60px] p-6">
	<div class="w-[800px] mx-auto bg-white border rounded-lg p-6">
	<form [formGroup]="form" (ngSubmit)="onSubmit()">
		<div class="grid grid-cols-1 md:grid-cols-2 gap-4">
			<label class="form-control w-full p-2">
			  <div class="label">
			    <span class="label-text">First Name</span>
			  </div>
			  <input type="text" class="input input-bordered w-full bg-white" formControlName="firstName" required autofocus />
			</label>
			<label class="form-control w-full p-2">
			  <div class="label">
			    <span class="label-text">Last Name</span>
			  </div>
			  <input type="text" class="input input-bordered w-full bg-white" formControlName="lastName" required />
			</label>
		</div>
		<div class="grid grid-cols-1 md:grid-cols-2 gap-4">
			<label class="form-control w-full p-2">
			  <div class="label">
			    <span class="label-text">Date of Birth</span>
			  </div>
			  <input type="text" class="input input-bordered w-full bg-white" formControlName="dateOfBirth" placeholder="MM/DD/YYYY" required />
			</label>
			<label class="form-control w-full p-2">
			  <div class="label">
			    <span class="label-text">State</span>
			  </div>
			  <select class="select select-bordered bg-white" formControlName="state" required>
			    <option selected value="">Select a State</option>
			    <option *ngFor="let state of states" [value]="state" [innerHTML]="state"></option>
			  </select>
			</label>
		</div>
		<div class="grid grid-cols-1 md:grid-cols-2 gap-4">
			<label class="form-control w-full p-2">
			  <div class="label">
			    <span class="label-text">Member ID</span>
			  </div>
			  <input type="text" class="input input-bordered w-full bg-white" formControlName="memberId" required />
			</label>
			<label class="form-control w-full p-2">
			  <div class="label">
			    <span class="label-text">Payer</span>
			  </div>
				<app-select-payer
					[payers]="payers"
					[selectedPayerId]="form.get('payerId')?.value"
					(onChange)="onPayerSelected($event)"
					[class]="'h-full'"
				/>
			</label>
		</div>
		<div class="grid grid-cols-1 md:grid-cols-2 gap-4">
			<label class="form-control w-full p-2">
			  <div class="label">
			    <span class="label-text">Specialty Code</span>
			  </div>
			  <select class="select select-bordered bg-white" formControlName="specialtyCode" required>
			    <option selected value="">Select a Specialty Code</option>
				<option *ngFor="let specialtyCode of specialtyCodes" [value]="specialtyCode" [innerHTML]="specialtyCode"></option>
			  </select>
			</label>
			<label class="form-control w-full p-2">
			  <div class="label">
			    <span class="label-text">Place of Service</span>
			  </div>
			  <select class="select select-bordered bg-white" formControlName="placeOfServiceCode" required>
			    <option selected value="">Select a Place of Service</option>
				<option *ngFor="let placeOfService of placesOfService" [value]="placeOfService.code" [innerHTML]="placeOfService.name"></option>
			  </select>
			</label>
		</div>
		<button class="btn btn-accent text-white m-2 min-w-[180px]" type="submit" [disabled]="form.invalid">
			<span *ngIf="isSubmitting == false">Create Verification</span>
			<span *ngIf="isSubmitting == true" class="loading loading-spinner loading-sm text-white h-4 w-4 m-auto"></span>
		</button>
	</form>
	</div>
</div>