import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PayerService } from "../@shared/services/payer.service"
import { Payer } from "../@shared/models/payer"
import { NetworkStatusRecord, NetworkStatusRecordStatus, NetworkStatusRuleCondition } from '../@shared/types/network-records';
import { NetworkRecordsService } from '../@shared/services/network-records.service';
import { ALL_STATES } from '../@shared/const/states';

@Component({
  selector: 'app-create-rule',
  templateUrl: './create-rule.component.html',
  styles: [`
    div.row {
      margin-bottom: 0px;
    }
  `]
})
export class CreateRuleComponent {

  payers: Payer[] = []
  payerValues: { payerId: string, payerName: string }[] = []
  statusValues = Object.values(NetworkStatusRecordStatus)
  states: string[] = ALL_STATES
  keyValues: { value: keyof NetworkStatusRecord, displayName: string}[] = [
    { value: 'payerId', displayName: 'Payer' },
    { value: 'state', displayName: 'State' },
    { value: 'networkType', displayName: 'Network Type' },
    { value: 'relatedEntityPayerId', displayName: 'Related Entity Payer' },
  ]

  selectedStatus = ""
  conditions: Pick<NetworkStatusRuleCondition, 'key' | 'value'>[] = []

  constructor(
    private networkRecordsService: NetworkRecordsService,
    private payerService: PayerService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.payers = await this.payerService.getPayers()

    this.payerValues = this.payers.map(payer => {
      return {
        payerId: payer.payerId,
        payerName: this.getPayerName(payer.payerId)
      }
    })
  }

  private getPayerName(payerId: string) {
    return this.payers.find((payer: Payer) => payer.payerId === payerId || payer.aliasPayerIds.includes(payerId))?.payerName
  }

  async createRule() {
    if (!this.isInputValid()) {
      return
    }

    const isDuplicatedConditionsKey = this.conditions.some((condition, index) => {
      return this.conditions.some((otherCondition, otherIndex) => {
        return index !== otherIndex && condition.key === otherCondition.key
      })
    })
    if (isDuplicatedConditionsKey) {
      alert("Please remove duplicated conditions")
      return
    }

    const selectedStatus = this.selectedStatus as NetworkStatusRecordStatus

    try {
      await this.networkRecordsService.createNetworkRule(
        selectedStatus,
        this.conditions
      )

      this.router.navigate(['network-status'], { fragment: 'rules' });
    } catch (error: any) {
      alert("Something went wrong, please review the information provided in the form and try again")
    }
  }

  getNotSelectedConditions() {
    return this.keyValues.filter(keyValue => !this.conditions.some(condition => condition.key === keyValue.value))
  }

  addCondition() {
    if (this.conditions.length >= 4) {
      return;
    }
    this.conditions.push({ key: this.getNotSelectedConditions()[0].value, value: '' })
  }

  removeCondition(condition: Pick<NetworkStatusRuleCondition, 'key' | 'value'>) {
    this.conditions = this.conditions.filter((c: Pick<NetworkStatusRuleCondition, 'key' | 'value'>) => (c.key !== condition.key || c.value !== condition.value))
  }

  isInputValid() {
    return this.selectedStatus != null && this.selectedStatus != "" && this.conditions.length > 0 && this.conditions.every(
      condition => condition.key != null && condition.value != null && condition.value != '' && condition.value != undefined
    )
  }

}
