<div class="header">
	<h1>Network Status</h1>
</div>
<div class="filters">
	<select (change)="didChangeSelection($event)">
		<option value="">Mark as</option>
		<option value="inn">In Network</option>
		<option value="oon">Out of Network</option>
		<option value="pending">Pending</option>
	</select>
</div>
<table *ngIf="row">
	<thead>
		<td>Provider</td>
		<td>Payer</td>
		<td>Network</td>
		<td>State</td>
		<td>Plan</td>
		<td>Related Entity</td>
		<td>Status</td>
		<td>Verifications</td>
		<td>Denial Rate</td>
	</thead>
	<tbody>
		<tr>
			<td>{{ row.npi }}</td>
			<td>{{ row.payerName }}</td>
			<td>{{ row.insuranceTypeCode }}</td>
			<td>{{ row.state }}</td>
			<td>{{ row.planName }}</td>
			<td [class.empty]="row.relatedEntity == 'none'">{{ row.relatedEntity == "none" ? "-" : row.relatedEntity }}</td>
			<td class="status">
				<span [class]="row.networkStatus">{{ row.networkStatus == "inn" ? "In Network" : (row.networkStatus == "oon" ? "Out of Network" : "Pending") }}</span>
			</td>
			<td class="number">{{ row.verificationCount | number }}</td>
			<td class="number" [class.empty]="row.eraCount == 0">{{ row.eraCount == 0 ? "-" : ((1 - row.successRate) | percent) }}</td>
		</tr>
	</tbody>
</table>
<div *ngIf="row && row.claims && row.claims.length > 0">
	<div class="filters">
		<div class="filters-button">
			<a class="button">Filter</a>
			<div class="dropdowns-container">
				<section class="dropdowns">
					<div *ngFor="let filter of filtersArr">
						<h3>{{ filter.name }}</h3>
						<ul>
							<li *ngFor="let filterValue of filterValues[filter.value] || []" (click)="didSelectFilter(filter.value, filterValue)">
								<input type="checkbox" [checked]="filters[filter.value] == filterValue.value" /> {{ filterValue.name }}
							</li>
						</ul>
					</div>
				</section>
			</div>
		</div>
		<span class="total">{{ sortedRows().length | number }} of {{ row.claims.length | number }}</span>
	</div>
	<table>
		<thead>
			<td>Claim ID</td>
			<td>Status</td>
			<td>Adjustment Reason Code</td>
			<td>Received Date</td>
			<td></td>
		</thead>
		<tbody>
			<tr *ngFor="let row of sortedRows()">
				<td>{{ row.claimId }}</td>
				<td>{{ row.claimStatus }}</td>
				<td>{{ row.claimAdjustmentReasonCode }}</td>
				<td>{{ row.claimReceivedDate | date }}</td>
				<td>
					<a class="button">Download</a>
				</td>
			</tr>
		</tbody>
	</table>
</div>
