export const environment = {
  name: 'dev',
  production: false,
  UserPoolId: 'us-east-1_uqU2MPZ0Q',
  ClientId: '9v3dg2gr34n8v65uvq7husg29',
  ApiKey: '',
  ApiUrl: 'https://api.development.soharhealth.com/internal/',
  CognitoClientNameAttribute: 'custom:clientName',
  mailchimpClientId: '398704899193',
  mailchimpRedirectUri: 'https://app.dev.soharhealth.com/auth/mailchimp',
  MixpanelId: '1173bda976133a3d854940e2dcffe128',
  IntercomId: 'fh6hv2p8'
};
