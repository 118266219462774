export class Fact {

	constructor(
		public timestamp: string,
		public count: number
	) {}

	static fromJson(data: any): Fact {
		return new Fact(
			data.timestamp,
			data.count
		)
	}
}
