import { Component, OnInit } from '@angular/core';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { environment } from '../environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  private awsRum: AwsRum | undefined;
  title = 'sohar-health';

  constructor() {}

  ngOnInit() {

    try {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        identityPoolId: environment.RumIdentityPoolId,
        endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
        telemetries: ["performance","errors",
            [ 'http', {
            recordAllRequests: true,
            addXRayTraceIdHeader: true,
            urlsToInclude: [/.*/],
            urlsToExclude: [
              new RegExp('https://dataplane.rum.us-east-1.amazonaws.com/.+'),
              new RegExp('https://api-js.mixpanel.com/.+')
            ]
        } ]
        ],
        pagesToInclude: [/.*/],
        pagesToExclude: [],
        enableRumClient: true,
        allowCookies: true,
        enableXRay: true,
        recordResourceUrl: true,
        signing: true,
        batchLimit: 100
      };

      this.awsRum = new AwsRum(
        environment.RumMonitorId,
        '1.0.0',
        'us-east-1',
        config
      );
    } catch (error) {
      console.error("AWS RUM initialization failed:", error);
    }
  }
}
