export const NETWORK_TYPES: string[] = [
  "12",
  "43",
  "47",
  "C1",
  "CO",
  "EP",
  "GP",
  "HM",
  "HN",
  "IN",
  "IP",
  "MA",
  "MB",
  "MC",
  "MP",
  "OT",
  "PR",
  "PS",
  "QM",
  "SP",
  "WU"
]