export type NetworkStatusRecord = {
  id: number
	payerId: string
	payerName: string
	state: string
	networkType: string|null
	planName: string
	relatedEntityPayerId: string|null
	relatedEntityPayerName: string|null
	entityIdentifierCode: string|null
	status: string,
  // added by FE
  isExpanded?: boolean
  isSelected?: boolean
}

export type NetworkStatusRequestStatsRecord = {
	inn: number,
	oon: number
}

export type NetworkStatusRecordFilters = {
	offset: number,
} & {
  [K in keyof NetworkStatusRecord]?: string;
}

export type NetworkStatusRecordFiltersValues = {
	statuses?: string[];
  payerIds: string[];
  states: string[];
  networkTypes: string[];
}

export type NetworkStatusEvent = {
	type: string,
	created: string,
}

export enum NetworkStatusRecordStatus {
	InNetwork = "inn",
	OutOfNetwork = "oon",
	Pending = "pending",
  // NotApplicable = "not-applicable"
}

export type NetworkStatusFullRule = NetworkStatusRule & {
	conditions: NetworkStatusRuleCondition[]
}

export type NetworkStatusRule = {
	id: number
	status: NetworkStatusRecordStatus
	//FE added
	displayName?: string
	isExpanded?: boolean
}

export type NetworkStatusRuleCondition = {
	id: number
	ruleId: number
	key: string
	value: string
	//FE added
	payerName?: string
}
