import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { VerificationService } from "../@shared/services/verification.service"
import { PayerService } from "../@shared/services/payer.service"
import { Payer } from "../@shared/models/payer"
import { Provider } from '../@shared/models/provider'
import { ALL_STATES } from '../@shared/const/states';

@Component({
  selector: 'app-create-patient',
  templateUrl: './create-patient.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatePatientComponent implements OnInit {

  @ViewChild('payerSelect') payerSelect: ElementRef<HTMLSelectElement>

  form: UntypedFormGroup
  payers: Payer[] = []
  states: string[] = ALL_STATES

  placesOfService = [
    {
      code: "02",
      name: "Telehealth Provided Other than in Patient’s Home"
    },
    {
      code: "10",
      name: "Telehealth Provided in Patient’s Home"
    },
    {
      code: "11",
      name: "Office"
    },
    {
      code: "12",
      name: "Home"
    },
    {
      code: "49",
      name: "Independent Clinic"
    }
  ]

  specialtyCodes = [
    "ABA",
    "IOP",
    "OCT",
    "PHT",
    "PRC",
    "PCH",
    "PSY",
    "SLP",
    "SUD",
    "UGC",
    "WMT"
  ]

  isSubmitting = false

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private verificationService: VerificationService,
    private payerService: PayerService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    const payerPromise = this.payerService.getPayers()

    const firstName = this.route.snapshot.queryParams["firstName"]
    const lastName = this.route.snapshot.queryParams["lastName"]
    const dateOfBirth = this.route.snapshot.queryParams["dateOfBirth"]
    const state = this.route.snapshot.queryParams["state"] || ""
    const memberId = this.route.snapshot.queryParams["memberId"]
    const payerId = this.route.snapshot.queryParams["payerId"] || ""
    const placeOfServiceCode = this.route.snapshot.queryParams["placeOfServiceCode"] || "11"
    const specialtyCode = "PSY"

    this.form = this.formBuilder.group({
      firstName: [firstName, [Validators.required]],
      lastName: [lastName, [Validators.required]],
      dateOfBirth: [dateOfBirth, [Validators.required]],
      state: [state, [Validators.required]],
      memberId: [memberId, [Validators.required]],
      payerId: ["", [Validators.required]],
      placeOfServiceCode: [placeOfServiceCode, Validators.required],
      specialtyCode: [specialtyCode, Validators.required]
    })

    this.payers = await payerPromise

    if (payerId == "") {
      setTimeout(() => {
        this.cd.detectChanges()
      }, 0)
      return
    }

    for (const payer of this.payers) {
      if (payerId == payer.payerId) {
        this.form.controls["payerId"].setValue(payer.payerId)
        break
      }

      const index = payer.aliasPayerIds.indexOf(payerId)

      if (index == -1) {
        continue
      }

      this.form.controls["payerId"].setValue(payer.payerId)

      break
    }

    setTimeout(() => {
      this.cd.detectChanges()
    }, 0)
  }

  async onSubmit() {
    if (this.form.invalid) {
      return
    }

    this.isSubmitting = true

    const firstName = this.form.value.firstName
    const lastName = this.form.value.lastName
    const dateOfBirth = this.form.value.dateOfBirth
    const state = this.form.value.state
    const memberId = this.form.value.memberId
    const payerId = this.form.value.payerId
    const placeOfServiceCode = this.form.value.placeOfServiceCode
    const specialtyCode = this.form.value.specialtyCode

    try {
      const verificationId = await this.verificationService.createVerification(
        firstName,
        lastName,
        dateOfBirth,
        state,
        memberId,
        payerId,
        placeOfServiceCode,
        specialtyCode,
        false)

      this.router.navigate(['/verifications', verificationId])
    } catch (error: any) {
      alert("Something went wrong, please review the information provided in the form and try again")
    } finally {
      this.isSubmitting = false
    }
  }

  onPayerSelected(selectedPayer: Payer) {
    this.form.patchValue({ payerId: selectedPayer.payerId });
  }
}
