<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
  <h1 class="text-xl font-bold">Create Verification</h1>
</div>

<div class="mt-[60px] p-6">
	<div class="max-w-[600px] mx-auto bg-white border rounded-lg p-6">
	<form [formGroup]="form" (ngSubmit)="onSubmit()">
		<div class="grid grid-cols-1 md:grid-cols-2 gap-4">
			<label class="form-control w-full max-w-xs p-2">
			  <div class="label">
			    <span class="label-text">First Name</span>
			  </div>
			  <input type="text" class="input input-bordered w-full max-w-xs bg-white" formControlName="firstName" required autofocus />
			</label>
			<label class="form-control w-full max-w-xs p-2">
			  <div class="label">
			    <span class="label-text">Last Name</span>
			  </div>
			  <input type="text" class="input input-bordered w-full max-w-xs bg-white" formControlName="lastName" required />
			</label>
		</div>
		<div class="grid grid-cols-1 md:grid-cols-2 gap-4">
			<label class="form-control w-full max-w-xs p-2">
			  <div class="label">
			    <span class="label-text">Date of Birth</span>
			  </div>
			  <input type="text" class="input input-bordered w-full max-w-xs bg-white" formControlName="dateOfBirth" placeholder="MM/DD/YYYY" required />
			</label>
			<label class="form-control w-full max-w-xs p-2">
			  <div class="label">
			    <span class="label-text">State</span>
			  </div>
			  <select class="select select-bordered bg-white" formControlName="state" required>
			    <option selected value="">Select a State</option>
			    <option *ngFor="let state of states" [value]="state" [innerHTML]="state"></option>
			  </select>
			</label>
		</div>
		<div class="grid grid-cols-1 md:grid-cols-2 gap-4">
			<label class="form-control w-full max-w-xs p-2">
			  <div class="label">
			    <span class="label-text">Member ID</span>
			  </div>
			  <input type="text" class="input input-bordered w-full max-w-xs bg-white" formControlName="memberId" required />
			</label>
			<label class="form-control w-full max-w-xs p-2">
			  <div class="label">
			    <span class="label-text">Payer</span>
			  </div>
			  <select class="select select-bordered bg-white" formControlName="payerId" required>
			    <option selected value="">Select a Payer</option>
				<option *ngFor="let payer of payers" [value]="payer.payerId" [innerHTML]="payer.payerName"></option>
			  </select>
			</label>
		</div>
		<div class="grid grid-cols-1 md:grid-cols-2 gap-4">
			<label class="form-control w-full max-w-xs p-2">
			  <div class="label">
			    <span class="label-text">Specialty Code</span>
			  </div>
			  <select class="select select-bordered bg-white" formControlName="specialtyCode" required>
			    <option selected value="">Select a Specialty Code</option>
				<option *ngFor="let specialtyCode of specialtyCodes" [value]="specialtyCode" [innerHTML]="specialtyCode"></option>
			  </select>
			</label>
			<label class="form-control w-full max-w-xs p-2">
			  <div class="label">
			    <span class="label-text">Place of Service</span>
			  </div>
			  <select class="select select-bordered bg-white" formControlName="placeOfServiceCode" required>
			    <option selected value="">Select a Place of Service</option>
				<option *ngFor="let placeOfService of placesOfService" [value]="placeOfService.code" [innerHTML]="placeOfService.name"></option>
			  </select>
			</label>
		</div>
		<button class="btn btn-accent text-white m-2">Create Verification</button>
	</form>
</div>
</div>