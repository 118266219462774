export class NetworkStatusObject {

	_isSelected = false

	_items: NetworkStatusObject[] = []

	get items(): NetworkStatusObject[] {
		return this._items.sort((a, b) => {
			return a.label.localeCompare(b.label)
		})
	}

	show = false

	get statusNumbers(): {
		inn: number,
		oon: number,
		unknown: number,
		total: number
	} {

		let inn = 0
		let oon = 0
		let unknown = 0
		let total = 0

		if (this.isPlan) {
			return {
				inn: 0,
				oon: 0,
				unknown: 0,
				total: 0
			}
		}

		for (const item of this._items) {
			if (item.isPlan) {
				total++

				if (item.networkStatus == "inn") {
					inn++
				} else if (item.networkStatus == "oon") {
					oon++
				} else {
					unknown++
				}
			} else {
				const numbers = item.statusNumbers

				inn += numbers.inn
				oon += numbers.oon
				unknown += numbers.unknown
				total += numbers.total
			}
		}

		return {
			inn,
			oon,
			unknown,
			total
		}
	}

	get statusMetrics(): {
		inn: number,
		oon: number,
		unknown: number
	} {
		const numbers = this.statusNumbers

		return {
			inn: (numbers.inn / numbers.total) * 100,
			oon: (numbers.oon / numbers.total) * 100,
			unknown: (numbers.unknown / numbers.total) * 100
		}
	}

	constructor(
		public label: string,
		public isPlan: boolean = false,
		public networkStatus: string|null = null
	) {}

	get isSelected(): number {
		if (this.isPlan) {
			return this._isSelected == true ? 2 : 0
		} else {
			const selectedItems = this._items.filter((item) => item.isSelected == 2)
			const indeterminateItems = this._items.filter((item) => item.isSelected == 1)

			if (selectedItems.length == this._items.length) {
				return 2
			} else if (selectedItems.length > 0 ||
				indeterminateItems.length > 0) {
				return 1
			} else {
				return 0
			}
		}
	}

	select(isSelected: boolean) {
		if (this.isPlan) {
			this._isSelected = isSelected
		} else {
			for (const item of this._items) {
				item.select(isSelected)
			}
		}
	}
}
