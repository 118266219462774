<div class="modal-box w-[900px] max-w-[900px]">

	<div class="min-h-[600px] mb-6">

		<label class="form-control w-full mb-6">
			<div class="label">
				<span class="label-text">Rule Name</span>
			</div>
			<input type="text" class="input input-bordered w-full bg-white" [(ngModel)]="name" placeholder="Rule Name" required />
		</label>

		<label class="form-control w-full mb-6">
			<div class="label">
				<span class="label-text">Status</span>
			</div>
			<select class="select select-bordered bg-white w-full h-[48px]" [(ngModel)]="status" required>
				<option value="inn">In Network</option>
				<option value="oon">Out of Network</option>
			</select>
		</label>

		<div class="mb-6 border bg-white rounded-lg p-4" *ngFor="let conditionKey of conditionKeys">

			<div class="flex gap-4 mb-4" *ngFor="let condition of groupedConditions[conditionKey]; let i = index">
				<select *ngIf="i === 0" class="select select-bordered bg-white w-[200px]" [(ngModel)]="condition.key" required>
					<option>Select a Value</option>
					<option *ngFor="let key of keyValues" [value]="key.value">{{ key.displayName }}</option>
				</select>

				<div *ngIf="i !== 0" class="h-[48px] leading-[48px] min-w-[200px]"></div>

				<div class="h-[48px] leading-[48px] min-w-[80px]">
					<span *ngIf="i === 0">is <b>Equal</b> to</span>
					<span *ngIf="i !== 0">OR</span>
				</div>

				<!-- For payer & relatedPayer selector -->
				<app-select-payer
				*ngIf="condition.key == 'payerId' || condition.key == 'relatedEntityPayerId'"
				[payers]="payers"
				[selectedPayerId]="condition.value"
				(onChange)="onPayerSelected($event, condition)"
				class="w-full"
				/>

				<!-- For state selector -->
				<select class="select select-bordered w-full bg-white" *ngIf="condition.key == 'state'" [(ngModel)]="condition.value">
					<option [ngValue]="undefined">Select a State</option>
					<option *ngFor="let state of states" [value]="state">{{ state }}</option>
				</select>

				<!-- For networkType selector -->
				<select class="select select-bordered w-full bg-white" *ngIf="condition.key == 'networkType'" [(ngModel)]="condition.value">
					<option [ngValue]="undefined">Select a Type</option>
					<option *ngFor="let type of networkTypes" [value]="type">{{ type }}</option>
				</select>

				<button class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100 w-[48px] font-normal h-[48px]" (click)="removeCondition(condition)"><svg class="h-[12px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="_01_align_center" data-name="01 align center"><path d="M22,4H17V2a2,2,0,0,0-2-2H9A2,2,0,0,0,7,2V4H2V6H4V21a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V6h2ZM9,2h6V4H9Zm9,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V6H18Z"/><rect x="9" y="10" width="2" height="8"/><rect x="13" y="10" width="2" height="8"/></g></svg></button>
			</div>

			<button class="btn btn-sm bg-white flex items-center hover:bg-gray-100" (click)="addGroupedCondition(conditionKey)"><svg class="h-[16px]" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path d="M17,11H13V7a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v4H7a1,1,0,0,0-1,1H6a1,1,0,0,0,1,1h4v4a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13h4a1,1,0,0,0,1-1h0A1,1,0,0,0,17,11Z"/></svg>Add Condition</button>
		</div>

		<button class="btn btn-sm bg-white flex items-center hover:bg-gray-100" (click)="addCondition()" *ngIf="numberOfConditions < 4"><svg class="h-[16px]" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path d="M17,11H13V7a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v4H7a1,1,0,0,0-1,1H6a1,1,0,0,0,1,1h4v4a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13h4a1,1,0,0,0,1-1h0A1,1,0,0,0,17,11Z"/></svg>Add Condition</button>
	</div>

	<button class="btn btn-accent text-white mr-2" (click)="didClickSaveRule()" [disabled]="!isInputValid()">Save Rule</button>

	<button *ngIf="id !== null" class="btn btn-error text-white mr-2" (click)="didClickDeleteRule()">Delete Rule</button>

	<button class="btn bg-white" (click)="didClickCloseModal()">Cancel</button>
</div>