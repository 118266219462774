import { Component } from '@angular/core';
import { PayerService } from '../@shared/services/payer.service'
import { Payer } from '../@shared/models/payer';
import { Papa } from 'ngx-papaparse'

@Component({
  selector: 'app-payers',
  templateUrl: './payers.component.html'
})
export class PayersComponent {

  constructor(
    private papa: Papa,
    private payerService: PayerService
  ) {}

  searchValue: string|null
  payers: Payer[] = []
  loading = false

  async ngOnInit() {
    this.loading = true
    this.payers = await this.payerService.getPayers()
    this.loading = false
  }

  didClickDownload() {
    if (!this.payers.length) {
      return
    }
    const rows: any = this.payers
    const csv = "data:text/csv;charset=utf-8," + this.papa.unparse(rows)
    const encodedUri = encodeURI(csv)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "sohar_payers.csv")
    document.body.appendChild(link)
    link.click()
  }

  get filteredPayers(): Payer[] {
    return this.payers.filter((payer) => {
      const searchValue = this.searchValue?.toLowerCase()
      const payerName = payer.payerName?.toLowerCase() ?? ""
      const payerId = payer.payerId?.toLowerCase() ?? ""
      return !searchValue ||
        payerName.includes(searchValue) ||
        payerId.includes(searchValue)
    })
  }

}
