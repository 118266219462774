<div class="dashboard">
	<div class="side-bar">
		<ul>
			<li class="logo"><a [routerLink]="['/']"><img src="assets/Logo Peach-White.svg"/></a></li>
			<li>
				<a [routerLink]="['/']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
					<img class="default-image" src="assets/home-smile.svg" />
					<img class="active-image" src="assets/home-smile-active.svg" />
					<span>Dashboard</span>
				</a>
			</li>
			<li>
				<a [routerLink]="['/verifications']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
					<img class="default-image" src="assets/users-03.svg" />
					<img class="active-image" src="assets/users-03-active.svg" />
					<span>Verifications</span>
				</a>
			</li>
			<li>
				<a [routerLink]="['/payers']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
					<img class="default-image" src="assets/providers.svg" />
					<img class="active-image" src="assets/providers-active.svg" />
					<span>Payers</span>
				</a>
			</li>
			<!-- <li>
				<a [routerLink]="['/reports']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
					<img class="default-image" src="assets/reports.svg" />
					<img class="active-image" src="assets/reports-active.svg" />
					<span>Reports</span>
				</a>
			</li> -->
		</ul>
		<ul class="bottom">
			<li>
				<a [routerLink]="['webhooks']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
					<img class="default-image" src="assets/browser.svg" />
					<img class="active-image" src="assets/browser-active.svg" />
					<span>Webhooks</span>
				</a>
			</li>
			<li>
				<a href="https://docs.soharhealth.com" target="_blank">
					<img class="default-image" src="assets/code-02.svg" />
					<span>Documentation</span>
				</a>
			</li>
			<li>
				<a [routerLink]="['settings']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
					<img class="default-image" src="assets/settings-02.svg" />
					<img class="active-image" src="assets/settings-02-active.svg" />
					<span>Settings</span>
				</a>
			</li>
		</ul>
	</div>
	<router-outlet></router-outlet>
</div>
