<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
  <h1 class="text-xl font-bold">Settings</h1>
	<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" [routerLink]="['/login']">
    <svg class="h-[12px]" fill="none" stroke="currentColor" stroke-width="2" viewBox="0 0 24 24">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H9m4 8h-7a2 2 0 01-2-2V6a2 2 0 012-2h7"/>
</svg>
    Sign Out
  </a>
</div>

<div class="p-6 bg-white min-h-screen">
  <!-- Tabs -->
	  <div class="tabs tabs-bordered inline-flex">
	    <a class="tab h-[48px]" [ngClass]="{ 'tab-active': activeTab === 'users' }" (click)="setActiveTab('users')">Users</a>
	    <a *ngIf="isAdminUser" class="tab h-[48px]" [ngClass]="{ 'tab-active': activeTab === 'credentials' }" (click)="setActiveTab('credentials')">API Credentials</a>
	  </div>

  <!-- Table 1 -->
  <div *ngIf="activeTab === 'users'" class="mt-6">
    <table class="table w-full">
      <thead class="bg-gray-200 rounded-lg">
        <tr class="rounded-lg border-b-0">
          <th class="rounded-l-lg">Email Address</th>
          <th>Group</th>
          <th>Status</th>
          <th class="rounded-r-lg"></th>
        </tr>
      </thead>
      <tbody>
      	<tr *ngIf="isLoading" class="border-b-0">
          <td><div class="skeleton h-6 w-full"></div></td>
          <td><div class="skeleton h-6 w-full"></div></td>
          <td colspan="2"><div class="skeleton h-6 w-full"></div></td>
        </tr>
        <tr *ngFor="let user of users" [ngClass]="{ 'bg-gray-100': user.isAuthUser == true }">
          <td>{{ user.emailAddress }}</td>
          <td *ngIf="isAdminUser && !user.isAuthUser">
			<select class="select select-bordered select-sm w-full max-w-xs bg-white" [(ngModel)]="user.role" (change)="didChangeUserRole(user)">
				<option value="default">Default</option>
				<option value="admin">Admin</option>
			</select>
		</td>
          <td *ngIf="!isAdminUser || user.isAuthUser">{{ user.role == "admin" ? "Admin" : "Default" }}</td>
          <td>
            <span class="badge px-3 py-1 text-white" [ngClass]="{
              'badge-success': user.disabled == false,
              'badge-error': user.disabled == true
            }">
              {{ user.disabled ? "Inactive" : "Active" }}
            </span>
          </td>
          <td>
				<a class="btn btn-sm bg-white hover:bg-gray-100" *ngIf="isAdminUser && !user.isAuthUser && !user.disabled" (click)="didClickDisableUser(user)">Disable User</a>
				</td>
        </tr>
        <tr *ngIf="isAdminUser">
        	<td colspan="3">
        		<input
	        		class="input input-bordered input-sm w-full max-w-xs bg-white"
	        		type="text"
	        		placeholder="Email Address"
	        		[(ngModel)]="emailAddress" />
        	</td>
        	<td>
        		<a
	        		class="btn btn-sm bg-white hover:bg-gray-100"
	        		(click)="didClickInviteUser()"><svg class="h-[14px]" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><g><circle cx="256" cy="128" r="128"/>
	<path d="M256,298.667c-105.99,0.118-191.882,86.01-192,192C64,502.449,73.551,512,85.333,512h341.333   c11.782,0,21.333-9.551,21.333-21.333C447.882,384.677,361.99,298.784,256,298.667z"/></g></svg>Invite User</a>
        	</td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Table 2 -->
  <div *ngIf="activeTab === 'credentials'" class="mt-6">
    <table class="table w-full">
      <thead class="bg-gray-200 rounded-lg">
        <tr class="rounded-lg border-b-0">
          <th class="rounded-l-lg">Environment</th>
          <th class="rounded-r-lg">API Key</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Production</td>
          <td class="flex justify-between items-center gap-2"><span>{{ user?.productionApiKey }}</span><button (click)="copyText(user?.productionApiKey)">
    <svg class="h-[16px]" id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m18 5.086-5.086-5.086h-7.914a3 3 0 0 0 -3 3v17h16zm-14 12.914v-15a1 1 0 0 1 1-1h7v4h4v12zm18-9v15h-15v-2h13v-15z"/></svg>
  </button></td>
        </tr>
        <tr>
          <td>Sandbox</td>
          <td class="flex justify-between items-center gap-2"><span>{{ user?.sandboxApiKey }}</span><button (click)="copyText(user?.sandboxApiKey)">
    <svg class="h-[16px]" id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m18 5.086-5.086-5.086h-7.914a3 3 0 0 0 -3 3v17h16zm-14 12.914v-15a1 1 0 0 1 1-1h7v4h4v12zm18-9v15h-15v-2h13v-15z"/></svg>
  </button></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<!-- <div *ngIf="tab == 'users'">
	<table>
		<thead>
			<td>Email Address</td>
			<td>Group</td>
			<td>Status</td>
			<td></td>
		</thead>
		<tbody>
			<tr *ngFor="let user of users">
				<td [class.auth-user]="user.isAuthUser">{{ user.emailAddress }}</td>
				<td *ngIf="isAdminUser && !user.isAuthUser">
					<select [(ngModel)]="user.role" (change)="didChangeUserRole(user)">
						<option value="default">Default</option>
						<option value="admin">Admin</option>
					</select>
				</td>
				<td *ngIf="!isAdminUser || user.isAuthUser">{{ user.role == "admin" ? "Admin" : "Default" }}</td>
				<td>{{ user.disabled ? "Inactive" : "Active" }}</td>
				<td>
					<a class="button" *ngIf="isAdminUser && !user.isAuthUser && !user.disabled" (click)="didClickDisableUser(user)">Disable User</a>
				</td>
			</tr>
			<tr *ngIf="isAdminUser">
				<td colspan="2">
					<input [(ngModel)]="emailAddress" placeholder="Email Address" type="email" required spellcheck="false" />
				</td>
				<td>
					<a class="button" (click)="didClickInviteUser()">Invite User</a>
				</td>
				<td></td>
			</tr>
		</tbody>
	</table>
</div>
<div *ngIf="tab == 'credentials' && isAdminUser">
	<table>
		<thead>
			<td>Environment</td>
			<td>API Key</td>
		</thead>
		<tbody>
			<tr>
				<td>Production</td>
				<td>{{ user?.productionApiKey }}<a class="copy" (click)="copyText(user?.productionApiKey)"><img src="assets/CopyDark.png" /></a></td>
			</tr>
			<tr>
				<td>Sandbox</td>
				<td>{{ user?.sandboxApiKey }}<a class="copy" (click)="copyText(user?.sandboxApiKey)"><img src="assets/CopyDark.png" /></a></td>
			</tr>
		</tbody>
	</table>
</div>
 -->