import { Component, OnInit } from '@angular/core';
import { StatService } from '../@shared/services/stat.service'
import Chart from 'chart.js/auto'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {

  constructor(
    private statService: StatService
  ) {}

  public chartOne: any;

  numberOfEligibilityRequests = 0
  labels: string[] = []
  eligibilityRequests: number[] = []

  async ngOnInit() {
    const stats = await this.statService.getStats()

    this.numberOfEligibilityRequests = stats.total
    this.labels = stats.facts.map(fact => fact.dateLabel)
    this.eligibilityRequests = stats.facts.map(fact => fact.count)

    Chart.defaults.font.family = "DM Sans"
    Chart.defaults.plugins.legend.display = false

    this.chartOne = this.getChart("chartOne", this.labels, this.eligibilityRequests, 'rgba(43, 161, 147, 1)', 'rgba(43, 161, 147, 0.05)')
  }

  getChart(id, labels, data, color, backgroundColor) {
    return new Chart(id, {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            data,
            borderWidth: 2,
            borderColor: color,
            backgroundColor: color,
            pointStyle: false,
            fill: {
              target: 'origin',
              above: backgroundColor
            }
          }
        ]
      },
      options: {
        animation: false,
        layout: {
          padding: {
            top: 24
          }
        },
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    })
  }
}

