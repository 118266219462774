import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { VerificationService } from "../@shared/services/verification.service"
import { PayerService } from "../@shared/services/payer.service"
import { Payer } from "../@shared/models/payer"
import { Provider } from '../@shared/models/provider'

@Component({
  selector: 'app-create-patient',
  templateUrl: './create-patient.component.html'
})
export class CreatePatientComponent {

  form: UntypedFormGroup
  payers: Payer[] = []
  states: string[] = [
    "AK",
    "AL",
    "AR",
    "AS",
    "AZ",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "GU",
    "HI",
    "IA",
    "ID",
    "IL",
    "IN",
    "KS",
    "KY",
    "LA",
    "MA",
    "MD",
    "ME",
    "MI",
    "MN",
    "MO",
    "MP",
    "MS",
    "MT",
    "NC",
    "ND",
    "NE",
    "NH",
    "NJ",
    "NM",
    "NV",
    "NY",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TT",
    "TX",
    "UT",
    "VA",
    "VI",
    "VT",
    "WA",
    "WI",
    "WV",
    "WY"
  ]

  placesOfService = [
    {
      code: "02",
      name: "Telehealth Provided Other than in Patient’s Home"
    },
    {
      code: "10",
      name: "Telehealth Provided in Patient’s Home"
    },
    {
      code: "11",
      name: "Office"
    },
    {
      code: "12",
      name: "Home"
    },
    {
      code: "49",
      name: "Independent Clinic"
    }
  ]

  specialtyCodes = [
    "ABA",
    "IOP",
    "OCT",
    "PHT",
    "PRC",
    "PCH",
    "PSY",
    "SLP",
    "SUD",
    "UGC",
    "WMT"
  ]

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private verificationService: VerificationService,
    private payerService: PayerService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    const firstName = this.route.snapshot.queryParams["firstName"]
    const lastName = this.route.snapshot.queryParams["lastName"]
    const dateOfBirth = this.route.snapshot.queryParams["dateOfBirth"]
    const state = this.route.snapshot.queryParams["state"] || ""
    const memberId = this.route.snapshot.queryParams["memberId"]
    const payerId = this.route.snapshot.queryParams["payerId"] || ""
    const placeOfServiceCode = this.route.snapshot.queryParams["placeOfServiceCode"] || "11"
    const specialtyCode = "PSY"

    this.form = this.formBuilder.group({
      firstName: [firstName, [Validators.required]],
      lastName: [lastName, [Validators.required]],
      dateOfBirth: [dateOfBirth, [Validators.required]],
      state: [state, [Validators.required]],
      memberId: [memberId, [Validators.required]],
      payerId: [payerId, [Validators.required]],
      placeOfServiceCode: [placeOfServiceCode, Validators.required],
      specialtyCode: [specialtyCode, Validators.required]
    })

    this.payers = await this.payerService.getPayers()

    for (const payer of this.payers) {
      if (payerId == payer.payerId) {
        continue
      }

      const index = payer.aliasPayerIds.indexOf(payerId)

      if (index == -1) {
        continue
      }

      this.form.controls["payerId"].setValue(payer.payerId)
    }
  }

  async onSubmit() {
    if (this.form.invalid) {
      return
    }

    const firstName = this.form.value.firstName
    const lastName = this.form.value.lastName
    const dateOfBirth = this.form.value.dateOfBirth
    const state = this.form.value.state
    const memberId = this.form.value.memberId
    const payerId = this.form.value.payerId
    const placeOfServiceCode = this.form.value.placeOfServiceCode
    const specialtyCode = this.form.value.specialtyCode

    try {
      const verificationId = await this.verificationService.createVerification(
        firstName,
        lastName,
        dateOfBirth,
        state,
        memberId,
        payerId,
        placeOfServiceCode,
        specialtyCode)

      console.log(verificationId)

      this.router.navigate(['/verifications', verificationId])
    } catch (error: any) {
      alert("Something went wrong, please review the information provided in the form and try again")
    }
  }
}
