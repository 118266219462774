<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
  <h1 class="text-xl font-bold">Create Rule</h1>
</div>

<div class="mt-[60px] p-6">
	<div class="max-w-[600px] mx-auto bg-white border rounded-lg p-6">
	<div>
		<select class="select select-bordered bg-white w-full" [(ngModel)]="selectedStatus" required>
			<option value="">Select a Network Status</option>
			<option *ngFor="let status of statusValues" [value]="status">{{ status }}</option>
		</select>

		<div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6 mb-6" *ngFor="let condition of conditions">
			<select class="select select-bordered select-sm w-full max-w-xs bg-white" [(ngModel)]="condition.key" required>
				<option>Select a Value</option>
				<option *ngFor="let key of keyValues" [value]="key.value">{{ key.displayName }}</option>
			</select>

			<select class="select select-bordered select-sm w-full max-w-xs bg-white" *ngIf="condition.key == 'payerId' || condition.key == 'relatedEntityPayerId'" [(ngModel)]="condition.value">
				<option>Select a Payer</option>
				<option *ngFor="let payer of payerValues" [value]="payer.payerId">{{ payer.payerName }}</option>
			</select>
			<!-- For state selector -->
			<select class="select select-bordered select-sm w-full max-w-xs bg-white" *ngIf="condition.key == 'state'" [(ngModel)]="condition.value">
				<option>Select a State</option>
				<option *ngFor="let state of states" [value]="state">{{ state }}</option>
			</select>
			<!-- For text input -->
			<input class="input input-bordered input-sm w-full max-w-xs bg-white"*ngIf="condition.key == 'networkType'" type="text" [(ngModel)]="condition.value" />

			<div class="flex items-center justify-center">
				<button class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="removeCondition(condition)"><svg class="h-[12px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="_01_align_center" data-name="01 align center"><path d="M22,4H17V2a2,2,0,0,0-2-2H9A2,2,0,0,0,7,2V4H2V6H4V21a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V6h2ZM9,2h6V4H9Zm9,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V6H18Z"/><rect x="9" y="10" width="2" height="8"/><rect x="13" y="10" width="2" height="8"/></g></svg>Remove</button>
			</div>
		</div>

		<div class="flex items-center justify-center m-6">
			<button class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="addCondition()" *ngIf="conditions.length < 4"><svg class="h-[16px]" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path d="M17,11H13V7a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v4H7a1,1,0,0,0-1,1H6a1,1,0,0,0,1,1h4v4a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13h4a1,1,0,0,0,1-1h0A1,1,0,0,0,17,11Z"/></svg>Add Condition</button>
		</div>

		<button class="btn btn-accent text-white w-full mt-4" (click)="createRule()">Create Rule</button>

	</div>
</div>