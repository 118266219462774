export class Verification {

	constructor(
		public verificationId: string,
		public created: string,
		public status: string,
		public benefits: {
			networkStatus: string,
			tier: number|null,
			copay: number|null,
			coinsurance: number|null,
			deductible: {
				total: number|null,
				remaining: number|null,
				coverageLevelCode: string|null
			},
			outOfPocket: {
				total: number|null,
				remaining: number|null
			},
		}[],
		public relatedEntities: {
			id: string|null,
			name: string|null,
			memberId: string|null,
			entityIdentifierCode: string|null
		}[],
		public patient: {
			firstName: string,
			lastName: string,
			dateOfBirth: string,
			memberId: string,
			subscriberRelationshipCode: string|null,
			address: {
				address1: string|null,
				address2: string|null,
				city: string|null,
				state: string|null,
				zipCode: string|null,
			}
		},
		public subscriber: {
			firstName: string,
			lastName: string
		},
		public plan: {
			name: string|null,
			insuranceTypeCode: string|null,
			startDate: string|null,
			endDate: string|null
		},
		public payer: {
			id: string,
			name: string
		},
		public group: {
			number: string|null,
			name: string|null
		}) {}

	get coveragePeriod(): string|null {
		if (this.plan.startDate && this.plan.endDate) {
			return `${this.plan.startDate} to ${this.plan.endDate}`
		} else if (this.plan.startDate) {
			return `From ${this.plan.startDate}`
		} else {
			return null
		}
	}

	get truncatedVerificationId(): string {
		return this.verificationId.slice(0, 8)
	}

	static fromJson(data: any): Verification {
		return new Verification(
			data.verificationId,
			data.created,
			data.status,
			data.benefits,
			data.relatedEntities,
			data.patient,
			data.subscriber,
			data.plan,
			data.payer,
			data.group)
	}
}
