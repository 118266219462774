import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {

  constructor(private http: HttpClient) {}

  rows: any[] = []

  async getRows(): Promise<{
    id: number,
    payerName: string,
    state: string,
    insuranceTypeCode: string,
    planName: string,
    networkStatus: string,
    npi: string
  }[]> {
    if (this.rows.length) {
      return this.rows
    }

    this.rows = await this.http.get('/assets/data.json').toPromise() as any[]

    return this.rows
  }

  async getRow(id: number): Promise<any|null> {
    const rows = await this.getRows()

    const row = rows.filter(row => row.id == id)[0]

    if (row == undefined) {
      return null
    }

    return row
  }
}
