<div class="filters fixed">
	<select *ngIf="selectedPlans.length > 0" (change)="didChangeNetworkStatusSelection($event)">
		<option value="">Set {{ selectedPlans.length }} plans to</option>
		<option value="inn">In Network</option>
		<option value="oon">Out of Network</option>
	</select>
</div>
<div class="row">
	<ul class="providers">
		<li *ngFor="let provider of providers" (click)="selectedProvider = provider" [class.selected]="selectedProvider == provider">
			<span>{{ provider }}</span>
			<h4>Provider Name M.D.</h4>
		</li>
		<li>
			<input type="text" name="Provider" placeholder="Add an NPI" />
		</li>
	</ul>
	<ul class="network-status" *ngIf="selectedProvider">
		<li *ngFor="let payer of rows">
			<div>
				<a class="arrow" (click)="payer.show = !payer.show" [class.show]="payer.show"></a>
				<a class="checkbox" [class.selected]="payer.isSelected == 2" [class.indeterminate]="payer.isSelected == 1" (click)="payer.select(payer.isSelected == 2 ? false : true)"></a>
				<span>{{ payer.label }}</span>
				<span class="network-status-status-bar">
					<span class="network-status-status-bar-inn" [style.width.%]="payer.statusMetrics.inn"></span>
					<span class="network-status-status-bar-unknown" [style.width.%]="payer.statusMetrics.unknown"></span>
					<span class="network-status-status-bar-oon" [style.width.%]="payer.statusMetrics.oon"></span>
				</span>
			</div>
			<ul *ngIf="payer.show">
				<li *ngFor="let state of payer.items">
					<div>	
						<a class="arrow" (click)="state.show = !state.show" [class.show]="state.show"></a>
						<a class="checkbox" [class.selected]="state.isSelected == 2" [class.indeterminate]="state.isSelected == 1" (click)="state.select(state.isSelected == 2 ? false : true)"></a>
						<span>{{ state.label }}</span>
						<span class="network-status-status-bar">
							<span class="network-status-status-bar-inn" [style.width.%]="state.statusMetrics.inn"></span>
							<span class="network-status-status-bar-unknown" [style.width.%]="state.statusMetrics.unknown"></span>
							<span class="network-status-status-bar-oon" [style.width.%]="state.statusMetrics.oon"></span>
						</span>
					</div>
					<ul *ngIf="state.show">
						<li *ngFor="let network of state.items">
							<div>
								<a class="arrow" (click)="network.show = !network.show" [class.show]="network.show"></a>
								<a class="checkbox" [class.selected]="network.isSelected == 2" [class.indeterminate]="network.isSelected == 1" (click)="network.select(network.isSelected == 2 ? false : true)"></a>
								<span>{{ network.label }}</span>
								<span class="network-status-status-bar">
									<span class="network-status-status-bar-inn" [style.width.%]="network.statusMetrics.inn"></span>
									<span class="network-status-status-bar-unknown" [style.width.%]="network.statusMetrics.unknown"></span>
									<span class="network-status-status-bar-oon" [style.width.%]="network.statusMetrics.oon"></span>
								</span>
							</div>
							<ul *ngIf="network.show">
								<li *ngFor="let plan of network.items">
									<div>
										<a class="checkbox" [class.selected]="plan.isSelected == 2" (click)="plan.select(!plan.isSelected)"></a>
										<span>{{ plan.label }}</span>
										<span class="network-status-status">{{ plan.networkStatus == "inn" ? "In Network" : (plan.networkStatus == "oon" ? "Out of Network" : "Unknown") }}</span>
									</div>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ul>
		</li>
		<li>
			<input type="text" name="Payer" placeholder="Add a payer" />
		</li>
	</ul>
</div>