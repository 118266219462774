import { Component } from '@angular/core';
import { Papa } from 'ngx-papaparse'
import { PayerService } from "../@shared/services/payer.service"
import { VerificationService } from "../@shared/services/verification.service"
import { Payer } from "../@shared/models/payer"
import * as levenshtein from "js-levenshtein"

@Component({
  selector: 'app-create-verifications',
  templateUrl: './create-verifications.component.html'
})
export class CreateVerificationsComponent {

  rows: any[] = []

  payerMap: { [payerId: string]: string } = {}

  isLoading = false

  constructor(
    private papa: Papa,
    private payerService: PayerService,
    private verificationService: VerificationService) {}

  async ngOnInit() {

    const payers = await this.payerService.getPayers()

    for (const payer of payers) {
      this.payerMap[payer.payerId] = payer.payerName
    }
  }

  onFileSelected(event: any) {
    const file = event.target.files[0]

    if (!file) {
      alert("Invalid file")
      return
    }

    this.papa.parse(file, {
      header: true,
      transformHeader: (h) => {
        return h.trim()
      },
      complete: (results, file) => {
        if (!results || !results.data) {
          alert("Invalid file")
          return
        }

        this.rows = results.data
      }
    })
  }

  async didClickUpload() {
    this.isLoading = true

    for (let i = 0; i < this.rows.length; i++) {

      const row = this.rows[i]

      try {
        const verificationId = await this.verificationService.createVerification(
          row.firstName,
          row.lastName,
          row.dateOfBirth,
          row.state,
          row.memberId,
          row.payerId,
          row.placeOfServiceCode,
          row.specialtyCode,
          false)

        this.rows[i].status = 201

        this.rows[i].verificationId = verificationId
      } catch (error: any) {
        this.rows[i].status = error.status

        this.rows[i].param = error?.error?.param
      }
    }

    this.isLoading = false
  }
}
