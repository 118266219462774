<div class="header">
	<h1>Settings</h1>
	<div>
		<a [routerLink]="['/login']" class="button">Log Out</a>
	</div>
</div>
<ul class="tabs">
	<li [class.active]="tab == 'users'"><a (click)="tab = 'users'">Users</a></li>
	<li *ngIf="isAdminUser" [class.active]="tab == 'credentials'"><a (click)="tab = 'credentials'">API Credentials</a></li>
</ul>
<div *ngIf="tab == 'users'">
	<table>
		<thead>
			<td>Email Address</td>
			<td>Group</td>
			<td>Status</td>
			<td></td>
		</thead>
		<tbody>
			<tr *ngFor="let user of users">
				<td [class.auth-user]="user.isAuthUser">{{ user.emailAddress }}</td>
				<td *ngIf="isAdminUser && !user.isAuthUser">
					<select [(ngModel)]="user.role" (change)="didChangeUserRole(user)">
						<option value="default">Default</option>
						<option value="admin">Admin</option>
					</select>
				</td>
				<td *ngIf="!isAdminUser || user.isAuthUser">{{ user.role == "admin" ? "Admin" : "Default" }}</td>
				<td>{{ user.disabled ? "Inactive" : "Active" }}</td>
				<td>
					<a class="button" *ngIf="isAdminUser && !user.isAuthUser && !user.disabled" (click)="didClickDisableUser(user)">Disable User</a>
				</td>
			</tr>
			<tr *ngIf="isAdminUser">
				<td colspan="2">
					<input [(ngModel)]="emailAddress" placeholder="Email Address" type="email" required spellcheck="false" />
				</td>
				<td>
					<a class="button" (click)="didClickInviteUser()">Invite User</a>
				</td>
				<td></td>
			</tr>
		</tbody>
	</table>
</div>
<div *ngIf="tab == 'credentials' && isAdminUser">
	<table>
		<thead>
			<td>Environment</td>
			<td>API Key</td>
		</thead>
		<tbody>
			<tr>
				<td>Production</td>
				<td>{{ user?.productionApiKey }}<a class="copy" (click)="copyText(user?.productionApiKey)"><img src="assets/CopyDark.png" /></a></td>
			</tr>
			<tr>
				<td>Sandbox</td>
				<td>{{ user?.sandboxApiKey }}<a class="copy" (click)="copyText(user?.sandboxApiKey)"><img src="assets/CopyDark.png" /></a></td>
			</tr>
		</tbody>
	</table>
</div>
