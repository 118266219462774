<div class="flex justify-between items-center mb-4">
	<div class="flex gap-4">
		<select class="select select-bordered bg-white" *ngFor="let filter of allFilters"[(ngModel)]="selectedFilters[filter.name]" (change)="didSelectFilter()">
			<option [ngValue]="undefined">All {{filter.values}}</option>
			<option *ngFor="let value of filterValues[filter.values]" value="{{value.value}}">
				{{ value.option }}
			</option>
		</select>
		<select class="select select-bordered bg-white" *ngIf="getAmountOfSelectedRecords() > 0" (change)="didChangeStatusSelection($event)">
			<option value="">Set {{ getAmountOfSelectedRecords() }} to</option>
			<option *ngFor="let status of statusValues" [value]="status">{{ status }}</option>
		</select>
	</div>
</div>

<div class="overflow-x-auto">
	<table class="table w-full">
		<thead class="bg-gray-200 rounded-lg">
			<tr class="rounded-lg border-b-0">
				<th class="rounded-l-lg"><input type="checkbox" class="checkbox checkbox bg-white" (click)="didClickSelectAll()" [class.selected]="areAllSelected()" /></th>
				<th>Payer</th>
				<th>State</th>
				<th>Insurance Type Code</th>
				<th>Plan</th>
				<th>Related Entity</th>
				<th>Entity Identifier Code</th>
				<th class="rounded-r-lg">Status</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngIf="loading" class="border-b-0">
				<td><div class="skeleton h-6 w-full"></div></td>
				<td><div class="skeleton h-6 w-full"></div></td>
				<td><div class="skeleton h-6 w-full"></div></td>
				<td><div class="skeleton h-6 w-full"></div></td>
				<td><div class="skeleton h-6 w-full"></div></td>
				<td><div class="skeleton h-6 w-full"></div></td>
				<td><div class="skeleton h-6 w-full"></div></td>
				<td><div class="skeleton h-6 w-full"></div></td>
			</tr>
			<tr *ngFor="let record of records">
				<td (click)="$event.stopPropagation()"><input type="checkbox" [checked]="record.isSelected" class="checkbox checkbox bg-white" [(ngModel)]="record.isSelected" /></td>
				<td>{{ record.payerName }}</td>
				<td>{{ record.state }}</td>
				<td>{{ record.networkType }}</td>
				<td>{{ record.planName }}</td>
				<td>{{ record.relatedEntityPayerName }}</td>
				<td>{{ record.entityIdentifierCode }}</td>
				<td class="min-w-[140px]">
					<select class="select select-bordered select-sm w-full max-w-xs bg-white" [(ngModel)]="record.status" (change)="didSelectStatus(record)" (click)="$event.stopPropagation()">
						<option *ngFor="let status of statusValues" [value]="status">{{ status }}</option>
					</select>
				</td>
			</tr>
		</tbody>
	</table>

	<div class="flex justify-between items-center mb-4 mt-6">
		<div class="join">
			<button class="join-item btn" [class.btn-active]="page == currentPage" (click)="getRecords(page)" *ngFor="let page of pages">{{ page }}</button>
		</div>
		<span class="text-sm text-gray-600 p-4">Showing {{ records.length | number }} of {{ totalRecords | number }}</span>
	</div>

</div>
