import { Component, Input, ViewChild, ElementRef } from '@angular/core'
import { NetworkRecordsService } from '../@shared/services/network-records.service'
import { NetworkStatusFullRule } from '../@shared/types/network-records'
import { Payer } from '../@shared/models/payer'

@Component({
  selector: 'app-network-rules',
  templateUrl: './network-rules.component.html'
})
export class NetworkRulesComponent {
  @Input()
  payers: Payer[]

  @ViewChild("createRuleModal") createRuleModal!: ElementRef<HTMLDialogElement>

  rules: NetworkStatusFullRule[] = []

  totalRecords: number = 0
  loading = false
  limit = 100

  selectedRule: NetworkStatusFullRule|null = null

  constructor(
    private networkRecordsService: NetworkRecordsService,
  ) {}

  async ngOnInit() {
    this.getRules(1)
  }

  async getRules(page: number) {
    if (this.loading) {
      return
    }
    this.loading = true
    const { rules, total } = await this.networkRecordsService.getNetworkRules((page - 1) * this.limit)

    this.rules = rules
    this.totalRecords = total
    this.loading = false
  }

  didClickCreateRule() {
    this.selectedRule = null
    this.createRuleModal.nativeElement.showModal()
  }

  didClickEditRule(rule: NetworkStatusFullRule) {
    this.selectedRule = structuredClone(rule)
    this.createRuleModal.nativeElement.showModal()
  }

  async didClickCloseModal(shouldReloadRules: boolean) {
    this.createRuleModal.nativeElement.close()

    if (shouldReloadRules == false) {
      return
    }

    this.rules = []
    await this.getRules(1)
  }
}
