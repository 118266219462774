import { Component } from '@angular/core'
import { environment } from '../../environments/environment'


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent {
  isNsVisible: boolean = false

  ngOnInit() {
    if (environment.production == false) {
      this.isNsVisible = true
    } else {
      // check customer
      // 7171e372-1045-4c52-a57c-e2e03548df03
    }
  }
}
