import { Component } from '@angular/core';
import { ProviderService } from '../@shared/services/provider.service';
import { CustomerProviderFilters, CustomerProviderRecord } from '../@shared/models/provider';
import { ActivatedRoute, Router } from '@angular/router';
import { PayerService } from '../@shared/services/payer.service';
import { Payer } from '../@shared/models/payer';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html'
})
export class ProvidersComponent {

  constructor(
    private payerService: PayerService,
    private providerService: ProviderService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  allFilters = [
    {
      name: 'payerId',
      values: 'Payers'
    },
    {
      name: 'state',
      values: 'States'
    },
  ]

  searchValue: string | null
  payers: Payer[] = []
  records: CustomerProviderRecord[] = []
  totalRecords: number = 0

  filterValues = {}
  selectedFilters: Omit<CustomerProviderFilters, "offset"> = {}

  loading = false
  limit = 100

  currentPage = 1

  async ngOnInit() {
    this.loading = true

    const filterPromise = this.providerService.getCustomerProvidersFilterValues()
    const payerPromise = this.payerService.getPayers()

    const queryParams: any = this.route.snapshot.queryParams

    if (queryParams) {
      this.selectedFilters = {
        payerId: queryParams.payerId ?? undefined,
        state: queryParams.state ?? undefined,
      }
      this.cleanSelectedFilters()
    }

    const resp = await Promise.all([filterPromise, payerPromise])
    const filterValues = resp[0]
    this.payers = resp[1]

    this.filterValues = {
      States: filterValues.states.map(val => ({ option: val, value: val})),
      Payers: filterValues.payerIds.map(val => ({ option: this.getPayerName(val), value: val})).sort((a, b) => (a.option || "").localeCompare(b.option || ""))
    }

    this.loading = false
    await this.getRecords(1)
  }

  async getRecords(page: number) {
    if (this.loading) {
      return
    }
    this.loading = true
    this.records = []

    const { records, total } = await this.providerService.getCustomerProviders({ offset: (page - 1) * this.limit, ...this.selectedFilters })
    this.records = records
    this.totalRecords = total
    this.currentPage = page
    this.loading = false
  }

  async deleteRecord(id: number) {
    if (confirm(`Are you sure you want to delete this provider?`)) {
      await this.providerService.deleteCustomerProviderRecord(id)
      await this.getRecords(this.currentPage)
    }
  }

  private getPayerName(payerId: string) {
    return this.payers.find((payer: Payer) => payer.payerId === payerId || payer.internalPayerId === payerId || payer.aliasPayerIds.includes(payerId))?.payerName
  }

  async didSelectFilter() {
    this.loading = true
    this.cleanSelectedFilters()
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { ...this.selectedFilters },
      fragment: this.route.snapshot.fragment
    })
    this.records = []
    this.loading = false
    await this.getRecords(1)
  }

  private cleanSelectedFilters() {
    this.selectedFilters = Object.fromEntries(
      Object.entries(this.selectedFilters).filter(([_, value]) => value !== undefined)
    )
  }

}
