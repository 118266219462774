export const environment = {
  name: 'dev',
  production: false,
  UserPoolId: 'us-east-1_uqU2MPZ0Q',
  ClientId: '9v3dg2gr34n8v65uvq7husg29',
  ApiKey: '',
  ApiUrl: 'https://api.development.soharhealth.com/internal/',
  CognitoClientNameAttribute: 'custom:clientName',
  MixpanelId: '1173bda976133a3d854940e2dcffe128',
  RumIdentityPoolId: 'us-east-1:45b87805-4086-4f3c-ba61-3450271074f9',
  RumMonitorId: '7527b405-3e95-4bf4-b125-0cfbc45dcad5'
};
