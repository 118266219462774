<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
  <h1 class="text-xl font-bold">Verification</h1>
  <a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="didClickRecreateVerification()">
  <img class="h-[14px]" src="assets/verification.svg" />
  Recreate Verification</a>
</div>

<div class="m-6">
  <div class="stats border bg-white w-full">
    <div class="stat">
      <div class="stat-title text-sm">Name</div>
      <div class="stat-value text-lg">{{ verification?.patient?.firstName }} {{ verification?.patient?.lastName }}</div>
    </div>

    <div class="stat">
      <div class="stat-title text-sm">Member ID</div>
      <div class="stat-value text-lg">{{ verification?.memberId }}</div>
    </div>

    <div class="stat">
      <div class="stat-title text-sm">Date Of Birth</div>
      <div class="stat-value text-lg">{{ verification?.patient?.dateOfBirth }}</div>
    </div>

    <div class="stat">
      <div class="stat-title text-sm">State</div>
      <div class="stat-value text-lg">{{ verification?.patient?.state }}</div>
    </div>

    <div class="stat">
      <div class="stat-title text-sm">Created</div>
      <div class="stat-value text-lg">{{ verification?.timestamp | date: 'MMM d, y h:mm a' }}</div>
    </div>

    <div class="stat">
      <div class="stat-title text-sm">Status</div>
      <div class="stat-value text-lg"><span 
    class="badge px-3 py-1 text-white font-normal"
    [ngClass]="{
  'badge-success': verification?.status.startsWith('complete'),
  'badge-warning': verification?.status.startsWith('pending'),
  'badge-error': verification?.status.startsWith('error')
}">{{ verification?.status }}</span></div>
    </div>
  </div>
</div>

<div class="m-6">
  <div class="stats border bg-white w-full">
    <div class="stat">
      <div class="stat-title text-sm">Payer</div>
      <div class="stat-value text-lg">{{ verification?.payerName }}</div>
    </div>

    <div class="stat">
      <div class="stat-title text-sm">Insurance Type Code</div>
      <div class="stat-value text-lg">{{ verification?.insuranceTypeCode || '-' }}</div>
    </div>

    <div class="stat">
      <div class="stat-title text-sm">Plan</div>
      <div class="stat-value text-lg">{{ verification?.planName }}</div>
    </div>

    <div class="stat">
      <div class="stat-title text-sm">Coverage Period</div>
      <div class="stat-value text-lg">{{ verification?.coveragePeriod }}</div>
    </div>
  </div>
</div>

<div class="m-6">
  <div class="bg-base-100 border rounded-lg p-6 bg-white">
    <h2 class="text-xl font-bold mb-4">Benefits</h2>
    <div class="overflow-x-auto">
      <table class="table w-full">
        <thead>
          <tr class="bg-gray-300 rounded-t-lg">
            <th class="rounded-tl-lg"></th>
            <th></th>
            <th></th>
            <th></th>
            <th colspan="2">Deductible</th>
            <th colspan="2" class="rounded-tr-lg">Out of Pocket</th>
          </tr>
          <tr class="bg-gray-200 border-b-0">
            <th class="rounded-bl-lg">Network Status</th>
            <th>Tier</th>
            <th>Copay</th>
            <th>Coinsurance</th>
            <th>Total</th>
            <th>Remaining</th>
            <th>Total</th>
            <th class="rounded-br-lg">Remaining</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let benefit of verification?.benefits || []">
            <td><span 
    class="badge px-3 py-1 text-white"
    [ngClass]="{
  'badge-success': benefit.networkStatus == 'inn',
  'badge-error': benefit.networkStatus == 'oon'
}">{{ benefit.networkStatus == "inn" ? "In Network" : "Out of Network" }}</span></td>
			<td>{{ benefit.tier == null ? '-' : benefit.tier }}</td>
			<td>{{ benefit.copay | currency }}</td>
			<td>{{ benefit.coinsurance | percent }}</td>
			<td>{{ benefit.totalDeductible | currency }}</td>
			<td>{{ benefit.remainingDeductible | currency }}</td>
			<td>{{ benefit.outOfPocket | currency }}</td>
			<td>{{ benefit.outOfPocketRemaining | currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="m-6">
  <div class="bg-base-100 border rounded-lg p-6 bg-white">
    <h2 class="text-xl font-bold mb-4">Related Entities</h2>
    <div class="overflow-x-auto">
      <table class="table w-full">
        <thead class="bg-gray-200 rounded-lg">
          <tr class="rounded-lg border-b-0">
            <th class="rounded-l-lg">Name</th>
            <th class="rounded-r-lg">Entity Identifier Code</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let relatedEntity of verification?.relatedEntities || []">
            <td>{{ relatedEntity.payerName }}</td>
            <td>{{ relatedEntity.entityIdentifierCode }}</td>
          </tr>
        </tbody>
      </table>
    </div>
</div>
