import { Component, OnInit } from '@angular/core';

import { CustomerService } from '../@shared/services/customer.service'
import { AuthService } from "../@shared/services/auth.service"
import { map } from 'rxjs/operators'
import { User } from '../@shared/models/user'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {

  tab = "users"
  user: User
  users: User[] = []
  isAdminUser: boolean = false
  emailAddress: string|null = null

  constructor(
    private customerService: CustomerService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    const groups = await this.authService.getUserGroups()
    this.isAdminUser = groups.includes("admin")
    this.users = await this.customerService.getUsers()
    if (this.isAdminUser) {
      this.user = await this.customerService.getUser()
    }
  }

  copyText(value) {
    navigator.clipboard.writeText(value)
  }

  async didChangeUserRole(user: User) {
    await this.customerService.updateUser(
      user.cognitoId,
      user.role)
  }

  async didClickDisableUser(user: User) {
    if (!this.isAdminUser) {
      return
    }

    const confirmed = confirm(`Are you sure you want to disable this user?`)

    if (!confirmed) {
      return
    }

    const cognitoId = user.cognitoId

    if (cognitoId == null ||
      cognitoId == undefined ||
      cognitoId == "") {
      return
    }

    try {
      await this.customerService.disableUser(cognitoId)
      this.users = await this.customerService.getUsers()
    } catch (error: any) {
      alert("Something went wrong, please try again")
    }
  }

  async didClickInviteUser() {
    if (!this.isAdminUser) {
      return
    }

    const emailAddress = this.emailAddress

    if (emailAddress == null ||
      emailAddress == undefined ||
      emailAddress == "") {
      return
    }

    try {
      this.emailAddress = null
      const user = await this.customerService.createUser(emailAddress)
      this.users = await this.customerService.getUsers()
    } catch (error: any) {
      if (
        error &&
        error.status &&
        error.status == 400 &&
        error.error &&
        error.error.error
      ) {
        alert(error.error.error)
        return
      }
      console.log(error)
      alert("Something went wrong, please try again")
    }
  }
}
