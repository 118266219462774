import { Component, Input } from '@angular/core'
import { NetworkRecordsService } from '../@shared/services/network-records.service'
import { NetworkStatusFullRule, NetworkStatusRecord, NetworkStatusRecordStatus, NetworkStatusRuleCondition } from '../@shared/types/network-records'
import { Payer } from '../@shared/models/payer'
import { ALL_STATES } from '../@shared/const/states'

@Component({
  selector: 'app-network-rules',
  templateUrl: './network-rules.component.html',
  styles: [`
    img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
    }
    .pointer {
      cursor: pointer;
    }
    div.row {
      margin-bottom: 0px;
    }
    .btn-width {
      width: -webkit-fill-available;
    }
  `]
})
export class NetworkRulesComponent {
  @Input()
  payers: Payer[]

  rules: NetworkStatusFullRule[] = []

  payerValues: { payerId: string, payerName: string }[] = []
  statusValues = Object.values(NetworkStatusRecordStatus)
  states: string[] = ALL_STATES

  totalRecords: number = 0
  loading = false

    keyValues: { value: keyof NetworkStatusRecord, displayName: string}[] = [
      { value: 'payerId', displayName: 'Payer' },
      { value: 'state', displayName: 'State' },
      { value: 'networkType', displayName: 'Network Type' },
      { value: 'relatedEntityPayerId', displayName: 'Related Entity Payer' },
    ]

  pages: number[] = []
  currentPage = 1

  constructor(
    private networkRecordsService: NetworkRecordsService,
  ) {}

  async ngOnInit() {
    this.loading = true
    this.payerValues = this.payers.map(payer => {
      return {
        payerId: payer.payerId,
        payerName: this.getPayerName(payer.payerId)
      }
    })
    this.loading = false
    await this.getRules(1)
  }

  async getRules(page: number) {
    if (this.loading) {
      return
    }
    this.currentPage = page
    this.rules = []
    this.loading = true
    const { rules, total } = await this.networkRecordsService.getNetworkRules(this.rules.length)

    const rulesWithNames = rules.map((record: NetworkStatusFullRule) => {
      const conditions = record.conditions.map((condition: NetworkStatusRuleCondition) => {
        if (condition.key === 'payerId' || condition.key === 'relatedEntityPayerId') {
          return {
            ...condition,
            payerName: this.getPayerName(condition.value)
          }
        }
        return condition
      })

      const displayName = this.getRuleDisplayName(conditions)

      return {
        ...record,
        displayName,
        conditions
      }
    })
    this.rules = this.rules.concat(rulesWithNames)
    console.log(this.rules)
    this.totalRecords = total
    this.loading = false
  }

  private getPayerName(payerId: string) {
    return this.payers.find((payer: Payer) => payer.payerId === payerId || payer.aliasPayerIds.includes(payerId))?.payerName
  }

  private getRuleDisplayName(conditions: NetworkStatusRuleCondition[]): string {
    let name = ''
    const firstCondition = conditions[0]
    const keyName = this.keyValues.find(keyValue => keyValue.value === firstCondition.key)?.displayName
    name += `<b>${keyName}</b>`
    name += ' is '
    let value = firstCondition.value
    if (firstCondition.key === 'payerId' || firstCondition.key === 'relatedEntityPayerId') {
      value = this.getPayerName(value)
    }
    name += `<b>${value}</b>`
    if (conditions.length > 1) {
      name += ` & ${conditions.length - 1} more`
    }
    return name
  }

  async didSelectStatus(rule: NetworkStatusFullRule) {
    this.loading = true
    await this.networkRecordsService.updateNetworkRule(rule.id, rule.status)
    this.rules = []
    this.loading = false
    await this.getRules(1)
  }

  async deleteRule(event: Event, rule: NetworkStatusFullRule) {
    event.stopPropagation()

    if (confirm(`Are you sure you want to delete rule?`)) {
      this.loading = true
      await this.networkRecordsService.deleteNetworkRule(rule.id)
      this.rules = []
      this.loading = false
      await this.getRules(1)
    }
  }

  private getNotSelectedConditions(rule: NetworkStatusFullRule) {
    return this.keyValues.filter(keyValue => !rule.conditions.some(condition => condition.key === keyValue.value))
  }

  addCondition(rule: NetworkStatusFullRule) {
    if (rule.conditions.length >= 4) {
      return;
    }
    rule.conditions.push({ key: this.getNotSelectedConditions(rule)[0].value, value: '', id: 0, ruleId: 0 })
  }

  removeCondition(rule: NetworkStatusFullRule, condition: Pick<NetworkStatusRuleCondition, 'key' | 'value'>) {
    rule.conditions = rule.conditions.filter((c) => (c.key !== condition.key || c.value !== condition.value))
  }

  async submitConditionChanges(event: Event, rule: NetworkStatusFullRule) {
    event.stopPropagation()

    const isDuplicatedConditionsKey = rule.conditions.some((condition, index) => {
      return rule.conditions.some((otherCondition, otherIndex) => {
        return index !== otherIndex && condition.key === otherCondition.key
      })
    })
    if (isDuplicatedConditionsKey) {
      alert("Please remove duplicated conditions")
      return
    }

    if (!this.isInputValid(rule)) {
      alert("Please fill all conditions")
      return
    }

    if (confirm(`Are you sure you want to update rule?`)) {
      this.loading = true
      await this.networkRecordsService.updateNetworkRule(rule.id, rule.status, rule.conditions)
      this.rules = []
      this.loading = false
      await this.getRules(1)
    }
  }

  isInputValid(rule: NetworkStatusFullRule) {
    return rule.status != null && rule.conditions.length > 0 && rule.conditions.every(
      condition => condition.key != null && condition.value != null && condition.value != '' && condition.value != undefined
    )
  }
}
