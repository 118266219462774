export class User {

	constructor(
		public customerName: string,
		public emailAddress: string,
		public customerId: string,
		public productionApiKey: string,
		public sandboxApiKey: string,
		public isAuthUser: boolean,
		public role: string|null,
		public cognitoId: string,
		public disabled: boolean
	) {}

	static fromJson(data: any): User {
		return new User(
			data.customerName,
			data.emailAddress,
			data.customerId,
			data.productionApiKey,
			data.sandboxApiKey,
			data.isAuthUser,
			data.role,
			data.cognitoId,
			data.disabled
		)
	}
}
