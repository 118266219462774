<div class="flex justify-between items-center mb-4">
	<div></div>
	<div class="flex gap-4">
		<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="didClickCreateRule()">
			<img class="max-h-[16px] max-w-[16px]" src="assets/network-status.svg" />
			Create Rule
		</a>
	</div>
</div>

<dialog #createRuleModal class="modal">
	<app-create-rule [rule]="selectedRule" [payers]="payers" (close)="didClickCloseModal($event)"></app-create-rule>
</dialog>

<div class="overflow-x-auto">
	<table class="table w-full">
		<thead class="bg-gray-200 rounded-lg">
			<tr class="rounded-lg border-b-0">
				<th class="rounded-l-lg">Rule</th>
				<th colspan="2" class="rounded-r-lg">Status</th>
			</tr>
		</thead>
		<tbody *ngIf="loading">
			<tr class="border-b-0">
				<td><div class="skeleton h-6 w-full"></div></td>
				<td colspan="2"><div class="skeleton h-6 w-full"></div></td>
			</tr>
		</tbody>
		<tbody *ngFor="let rule of rules; let i = index">
			<tr>
				<td [innerHTML]="rule.name || 'Rule ' + rule.id"></td>
				<td>{{ rule.status }}</td>
				<td>
					<button class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100 font-normal" (click)="didClickEditRule(rule)">Edit Rule</button>
				</td>
			</tr>
		</tbody>
	</table>

	<app-page-selector
		[recordsLength]="rules.length"
		[totalLength]="totalRecords"
		[limit]="limit"
		(onPageClick)="getRules($event)"
	/>
</div>
