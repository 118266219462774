import { Component, OnDestroy } from '@angular/core'
import { PayerService } from '../@shared/services/payer.service'
import { Payer } from '../@shared/models/payer'
import { Router } from '@angular/router'
import { TabManagerService } from '../@shared/services/tab-manager.service'
import { Chart, registerables } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { NetworkRecordsService } from '../@shared/services/network-records.service'

Chart.register(...registerables)

@Component({
  selector: 'app-network-status-view',
  templateUrl: './network-status-view.component.html',
})
export class NetworkStatusViewComponent extends TabManagerService implements OnDestroy {
  payers: Payer[] | null = null
  loading = false

  constructor(
    private networkRecordsService: NetworkRecordsService,
    private payerService: PayerService,
    private router: Router,
  ) {
    super(router, 'records')
  }

  override ngOnDestroy(): void {
    this.destroy()
  }

  async ngOnInit() {
    this.loading = true;

    this.payers = await this.payerService.getPayers()

    const stats = await this.networkRecordsService.getNetworkRecordsStats()
    const statsLength = stats.length

    const labels = [...Array.from({ length: statsLength - 1 }, (_, i) => {
      let date = new Date()
      date.setDate(date.getDate() - i)
      return date
    })]

    const innData = stats.map(stat => stat.inn)
    const oonData = stats.map(stat => stat.oon)

    Chart.defaults.font.family = "Inter"
    Chart.defaults.plugins.legend.display = false

    this.getBarChartConfig(labels, innData, oonData)

    this.loading = false;
  }

  getBarChartConfig(labels: Date[], innData: number[], oonData: number[]): Chart {
      return new Chart('chartOne', {
        type: 'bar',
        data: {
          labels,
          datasets: [
            {
              label: 'In Network',
              data: innData,
              backgroundColor: 'rgba(193, 219, 161, 1)',
              barThickness: 8,
              borderWidth: 1,
              borderColor: 'white',
              stack: 'bar',
            },
            {
              label: 'Out of Network',
              data: oonData,
              backgroundColor: '#FEC371',
              barThickness: 8,
              borderWidth: 1,
              borderColor: 'white',
              stack: 'bar',
            }
          ]
        },
        options: {
          responsive: true,
          plugins: {
            tooltip: {
              callbacks: {
                label: function(context) {
                  const value = context.raw || 0;
                  return `${context.dataset.label}: ${value}%`;
                }
              }
            }
          },
          scales: {
            x: {
              type: 'time', // Use time scale
              time: {
                unit: 'day', // Adjust granularity: 'day', 'month', 'year', etc.
                tooltipFormat: 'MMM d, yyyy', // Tooltip format
                displayFormats: {
                  day: 'MMM d' // Display format for labels
                }
              },
              ticks: {
                color: 'rgba(20, 25, 31, 0.3)',
                autoSkip: false
              },
              grid: {
                display: false
              },
              border: {
                display: false
              }
            },
            y: {
              beginAtZero: true,
              max: 100,
              position: 'right',
              ticks: {
                color: 'rgba(20, 25, 31, 0.3)',
                autoSkip: false
              },
              grid: {
                display: false
              },
              border: {
                display: false
              }
            }
          }
          // scales: {
          //   x: {
          //     stacked: true
          //   },
          //   y: {
          //     stacked: true,
          //     beginAtZero: true,
          //     max: 100,
          //     ticks: {
          //       callback: function(value) {
          //         return `${value}%`;
          //       }
          //     }
          //   }
          // }
        }
      });
    }
}
