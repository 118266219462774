<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
  <h1 class="text-xl font-bold">Network Status</h1>
</div>

<div class="p-6 border rounded-lg w-[calc(100%-3rem)] mx-auto bg-white mt-6">
	<canvas id="chartOne" height="80"></canvas>
	<div class="mt-6 flex">
    <div class="flex items-center mr-6">
      <span class="h-[12px] w-[12px] rounded-[6px] bg-[rgba(193,219,161,1)] mr-2"></span>
      <span class="text-sm text-gray-400">In Network</span>
    </div>
    <div class="flex items-center">
      <span class="h-[12px] w-[12px] rounded-[6px] bg-[#FEC371] mr-2"></span>
      <span class="text-sm text-gray-400">Out of Network</span>
    </div>
  </div>
</div>

<div class="p-6 border rounded-lg m-6 bg-white">

  <div class="tabs tabs-bordered inline-flex">
    <a class="tab h-[48px]" [ngClass]="{ 'tab-active': tab === 'records' }" (click)="navigateToTab('records')">Records</a>
    <a class="tab h-[48px]" [ngClass]="{ 'tab-active': tab === 'rules' }" (click)="navigateToTab('rules')">Rules</a>
  </div>

	<div class="mt-6" *ngIf="payers">
		<div *ngIf="tab == 'records'">
			<app-network-records
				[payers]="payers"
			/>
		</div>
		<div *ngIf="tab == 'rules'">
			<app-network-rules
				[payers]="payers"
			/>
		</div>
	</div>
</div>
