import { Component } from '@angular/core'
import { NetworkStatusService } from "../@shared/services/network-status.service"
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-network-status-detail',
  templateUrl: './network-status-detail.component.html'
})
export class NetworkStatusDetailComponent {

  constructor(
    private networkStatusService: NetworkStatusService,
    private route: ActivatedRoute
  ) {}

  row: any|null = null

  filtersArr: {
    name: string,
    value: string
  }[] = [
    {
      name: "Status",
      value: "claimStatus"
    },
    {
      name: "Adjustment Reason Code",
      value: "claimAdjustmentReasonCode"
    }
  ]

  filters: {
    [key: string]: string
  } = {}

  filterValues: {
    [key: string]: {
      name: string,
      value: string
    }[]
  } = {}

  didChangeSelection(event: any) {
    const element = event.target as HTMLSelectElement

    const value = element.value

    if (value == null ||
      value == undefined ||
      value == "") {
      return
    }

    this.row.networkStatus = value
  }

  didSelectFilter(filter: string, filterValue: any) {
    this.filters[filter] = filterValue.value
  }

  async ngOnInit() {
    const id = parseInt(this.route.snapshot.paramMap.get("id"))

    this.row = await this.networkStatusService.getRow(id)

    for (const row of this.row.claims || []) {
      for (const filter of this.filtersArr) {
        const key = filter.value
        const value = row[key]

        if (this.filterValues[key] == undefined) {
          this.filterValues[key] = []
        }

        const existingValues = this.filterValues[key].map(obj => obj.value)

        if (!existingValues.includes(value || "none")) {
          this.filterValues[key].push({
            name: value,
            value: value || "none"
          })
        }

        if (this.filters[key] == undefined) {
          this.filters[key] = ""
        }
      }
    }

    for (const filter in this.filterValues) {
      this.filterValues[filter] = this.filterValues[filter].sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })

      this.filterValues[filter].unshift({
        name: "Show All",
        value: ""
      })
    }
  }

  sortedRows(): any[] {
    if (this.row == null) {
      return []
    }

    const rows = this.row.claims || []

    return rows
      .filter((row) => {
        let res = true

        for (const filter in this.filters) {
          const value = row[filter]
          const filterValue = this.filters[filter]

          res = res && (filterValue == "" || value == filterValue)

          if (res == false) {
            return false
          }
        }

        return res
      })
  }
}
