import { Component } from '@angular/core'
import { environment } from '../../environments/environment'
import { AuthService } from "../@shared/services/auth.service"

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent {
  isNsVisible: boolean = false

  isNsVisibleCognitoIds = [
    "4db5bd12-62ce-4ba8-9add-c2e3770de8bc", // A, I
    "438a04df-f00b-42a3-a79f-4e87b83c2811", // L, S
    "f6dfd7be-438f-4ad4-b80d-e6b666854315", // I
    "ab603886-8966-4ade-a2d4-c2f251ac2629", // I
    "f80c3271-c4cd-48d4-9786-e2da057bc569", // I
    "e609d540-1925-42c2-9d0b-f5f73a682363", // I
    "7bde53f9-8119-4b32-9b0b-f2b0be5224b4", // I
    "e46e7a7a-0f22-43fe-9550-90048092e144", // I
    "1648a049-9647-4a9d-977a-24e2a4ef87d3", // I
    "116e4e54-943c-4772-8b9d-66f274c80a8b", // I
    "8b043a29-e42e-465e-b0c0-f525baecc155", // I
    "7407df62-d9ba-4baf-a402-dbd426addd48", // I
  ]

  constructor(
    private authService: AuthService
  ) {}

  async ngOnInit() {
    if (environment.production == false) {
      this.isNsVisible = true
    } else {
      const userCognitoId = await this.authService.getUserCognitoId()

      if (userCognitoId == null) {
        return
      }

      this.isNsVisible = this.isNsVisibleCognitoIds.includes(userCognitoId)
    }
  }
}
