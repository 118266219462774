<div class="flex justify-between items-center mb-4">
	<div class="flex gap-4">
		<select class="select select-bordered bg-white" *ngFor="let filter of allFilters"[(ngModel)]="selectedFilters[filter.name]" (change)="didSelectFilter()">
			<option [ngValue]="undefined">All {{filter.values}}</option>
			<option *ngFor="let value of filterValues[filter.values]" value="{{value.value}}">
				{{ value.option }}
			</option>
		</select>
		<select class="select select-bordered bg-white" *ngIf="getAmountOfSelectedRecords() > 0" (change)="didChangeStatusSelection($event)">
			<option value="">Set {{ getAmountOfSelectedRecords() }} to</option>
			<option *ngFor="let status of statusValues" [value]="status">{{ status }}</option>
		</select>
	</div>
</div>

<div class="overflow-x-auto">
	<table class="table w-full">
		<thead class="bg-gray-200 rounded-lg">
			<tr class="rounded-lg border-b-0">
				<th class="rounded-l-lg"><input type="checkbox" class="checkbox checkbox bg-white" (click)="didClickSelectAll()" [class.selected]="areAllSelected()" /></th>
				<th class="px-2">Payer</th>
				<th class="px-2">State</th>
				<th class="px-2">Insurance Type Code</th>
				<th class="px-2">Plan</th>
				<th class="px-2">Related Entity</th>
				<th class="px-2">Entity Identifier Code</th>
				<th class="px-2">Status</th>
				<th class="rounded-r-lg"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngIf="loading" class="border-b-0">
				<td><div class="skeleton h-6 w-full"></div></td>
				<td class="px-2"><div class="skeleton h-6 w-full"></div></td>
				<td class="px-2"><div class="skeleton h-6 w-full"></div></td>
				<td class="px-2"><div class="skeleton h-6 w-full"></div></td>
				<td class="px-2"><div class="skeleton h-6 w-full"></div></td>
				<td class="px-2"><div class="skeleton h-6 w-full"></div></td>
				<td class="px-2"><div class="skeleton h-6 w-full"></div></td>
				<td colspan="2"><div class="skeleton h-6 w-full"></div></td>
			</tr>
			<tr *ngFor="let record of records">
				<td (click)="$event.stopPropagation()"><input type="checkbox" [checked]="record.isSelected" class="checkbox checkbox bg-white block" [(ngModel)]="record.isSelected" /></td>
				<td class="px-2">{{ record.payerName }}</td>
				<td class="px-2">{{ record.state }}</td>
				<td class="px-2">{{ record.networkType }}</td>
				<td class="px-2">{{ record.planName }}</td>
				<td class="px-2">
					<span
						*ngIf="record.networkPayerId != null &&
						record.payerId != record.relatedEntityPayerId &&
						record.networkPayerId == record.relatedEntityPayerId"
						class="h-[12px] w-[12px] rounded-[6px] bg-[rgba(193,219,161,1)] mr-1 inline-block"></span>
					{{ record.relatedEntityPayerName }}
				</td>
				<td class="px-2">{{ record.entityIdentifierCode }}</td>
				<td class="px-2 min-w-[140px]">
					<select *ngIf="record.isLoading != true" class="select select-bordered select-sm w-full max-w-xs bg-white" [(ngModel)]="record.status" (change)="didSelectStatus(record)" (click)="$event.stopPropagation()">
						<option *ngFor="let status of statusValues" [value]="status">{{ status }}</option>
					</select>
					<div *ngIf="record.isLoading == true" class="w-full bg-white border border-gray-300 rounded-lg h-[30px] flex items-center">
					  <span class="loading loading-spinner loading-sm text-gray-600 h-4 w-4 m-auto"></span>
					</div>
				</td>
				<td>
					<div
						class="dropdown dropdown-end"
						*ngIf="record.relatedEntityPayerId != null && record.payerId != record.relatedEntityPayerId">
						<div tabindex="0" role="button" class="btn btn-outline border-gray-300 hover:bg-gray-100">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12"><circle cx="12" cy="2" r="2"/><circle cx="12" cy="12" r="2"/><circle cx="12" cy="22" r="2"/></svg>
						</div>
						<ul tabindex="0" class="dropdown-content menu border border-gray-200 bg-white rounded-box w-64 shadow-sm p-0">
							<li>
								<a
								(click)="didClickUpdateNetworkPayerId($event, record, record.payerId)">
									<span
										class="h-[12px] w-[12px] rounded-[6px]"
										[ngClass]="{'bg-[rgba(193,219,161,1)]': record.networkPayerId == null || record.payerId === record.networkPayerId }"></span>
									{{ record.payerName }}
								</a>
							</li>
							<li>
								<a
								(click)="didClickUpdateNetworkPayerId($event, record, record.relatedEntityPayerId)">
									<span
										class="h-[12px] w-[12px] rounded-[6px]"
										[ngClass]="{'bg-[rgba(193,219,161,1)]': record.relatedEntityPayerId === record.networkPayerId }"></span>
									{{ record.relatedEntityPayerName }}
								</a>
							</li>
						</ul>
					</div>
				</td>
			</tr>
		</tbody>
	</table>

	<app-page-selector
		[recordsLength]="records.length"
		[totalLength]="totalRecords"
		[limit]="limit"
		[page]="currentPage"
		(onPageClick)="getRecords($event)"
	/>

</div>
