import { Fact } from "./fact"

export class Stat {

	constructor(
		public total: number,
		public facts: Fact[]
	) {}

	static fromJson(data: any): Stat {
		return new Stat(
			data.total || 0,
			data.facts.map(fact => Fact.fromJson(fact))
		)
	}
}
