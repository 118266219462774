<div class="relative w-full" *ngIf="payers.length > 0">
  <!-- Select-Like Button -->
  <button
    *ngIf="dropdownOpen == false"
    class="select select-bordered h-[48px] w-full bg-white text-left px-3 flex items-center justify-between"
    [ngClass]="class"
    (click)="toggleDropdown($event)"
  >
    <span class="flex-1 truncate">{{ getSelectedPayerName() || 'Select a Payer' }}</span>
  </button>

  <!-- Search Input -->
  <div *ngIf="dropdownOpen">
    <input
      #searchField
      type="text"
      class="border rounded-lg w-full border-gray-300 bg-white text-left text-sm h-[48px] px-3 py-2 flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 focus:ring-offset-white"
      spellcheck="false"
      placeholder="Search payers..."
      [(ngModel)]="searchTerm"
      (input)="onSearchChange()" />
  </div>

  <!-- Dropdown -->
  <div *ngIf="dropdownOpen"
       class="absolute z-50 w-full mt-1 bg-white border rounded-lg shadow-md max-h-60 overflow-y-auto"
       (scroll)="onDropdownScroll($event)">

    <!-- Payer List -->
    <ul class="divide-y divide-gray-200">
      <li *ngFor="let payer of displayedPayers; trackBy: trackByPayerId"
          class="px-4 py-2 cursor-pointer whitespace-normal break-words overflow-hidden flex items-center justify-between"
          [ngClass]="{'bg-gray-100': payer.payerId === selectedPayerId, 'hover:bg-gray-100': payer.payerId !== selectedPayerId}"
          (click)="selectPayer(payer)">

        <!-- Left-aligned Payer Name -->
        <span class="truncate flex-1 text-sm">{{ payer.payerName }}</span>
      </li>
    </ul>
  </div>
</div>

<div *ngIf="payers.length == 0" class="w-full bg-white border border-gray-300 rounded-lg h-[48px] flex items-center">
  <span class="loading loading-spinner loading-sm text-gray-600 h-4 w-4 m-auto"></span>
</div>
