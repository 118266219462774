import { Component } from '@angular/core'
import { NetworkStatusService } from "../@shared/services/network-status.service"
import { Router } from '@angular/router'

@Component({
  selector: 'app-network-status',
  templateUrl: './network-status.component.html'
})
export class NetworkStatusComponent {

  constructor(
    private networkStatusService: NetworkStatusService,
    private router: Router
  ) {}

  rows: any[] = []

  filtersArr: {
    name: string,
    value: string
  }[] = [
    {
      name: "Status",
      value: "networkStatus"
    },
    {
      name: "Provider",
      value: "npi"
    },
    {
      name: "Payer",
      value: "payerName"
    },
    {
      name: "Network",
      value: "insuranceTypeCode"
    },
    {
      name: "State",
      value: "state"
    },
    {
      name: "Plan",
      value: "planName"
    },
    {
      name: "Related Entity",
      value: "relatedEntity"
    }
  ]

  filters: {
    [key: string]: string
  } = {}

  filterValues: {
    [key: string]: {
      name: string,
      value: string
    }[]
  } = {}

  filterValueNames = {
    "inn": "In Network",
    "oon": "Out of Network",
    "pending": "Pending",
    "none": "None"
  }

  didChangeSelection(event: any) {
    const element = event.target as HTMLSelectElement

    const value = element.value

    if (value == null ||
      value == undefined ||
      value == "") {
      return
    }

    for (const row of this.selectedRows()) {
      row.networkStatus = element.value
      row.isSelected = false
    }
  }

  didClickSelectAll() {
    const selected = this.selectedRows().length > 0

    for (const row of this.sortedRows()) {
      row.isSelected = !selected
    }
  }

  selectedRows() {
    return this.rows.filter(row => row.isSelected)
  }

  didSelectFilter(filter: string, filterValue: any) {
    this.filters[filter] = filterValue.value
  }

  async ngOnInit() {
    this.rows = await this.networkStatusService.getRows()

    for (const row of this.rows) {
      row.isSelected = false

      if (row.verificationCount == 0) {
        continue
      }

      for (const filter of this.filtersArr) {
        const key = filter.value
        const value = row[key]

        if (this.filterValues[key] == undefined) {
          this.filterValues[key] = []
        }

        const existingValues = this.filterValues[key].map(obj => obj.value)

        if (!existingValues.includes(value || "none")) {
          this.filterValues[key].push({
            name: this.filterValueNames[value] || value,
            value: value || "none"
          })
        }

        if (this.filters[key] == undefined) {
          this.filters[key] = ""
        }
      }
    }

    for (const filter in this.filterValues) {
      this.filterValues[filter] = this.filterValues[filter].sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })

      this.filterValues[filter].unshift({
        name: "Show All",
        value: ""
      })
    }
  }

  didClickRow(row: any) {
    this.router.navigate(['network-status-detail', row.id])
  }

  sortedRows(): any[] {
    return this.rows
      .sort((a, b) => {
        if (a.payerName == b.payerName) {
          return a.id > b.id ? 1 : -1
        }

        return a.payerName > b.payerName ? 1 : -1
      })
      .filter((row) => {
        let res = true

        for (const filter in this.filters) {
          const value = row[filter]
          const filterValue = this.filters[filter]

          res = res && (filterValue == "" || value == filterValue)

          if (res == false) {
            return false
          }
        }

        return res
      })
      .filter((row) => {
        return row.verificationCount > 0
      })
  }

}
