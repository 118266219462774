<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
  <h1 class="text-xl font-bold">Create Verifications</h1>
  <label *ngIf="rows.length == 0" class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100">
    <svg class="h-[14px]" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24"><path d="M11.007,2.578,11,18.016a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.007-15.421,2.912,2.913a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L14.122.879a3,3,0,0,0-4.244,0L6.667,4.091a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0Z"/><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z"/></svg>
    <input id="file-upload" type="file" (change)="onFileSelected($event)" accept=".csv">
    Select File
  </label>
  <a *ngIf="rows.length > 0 && isLoading == false" class="btn btn-sm bg-accent flex items-center text-white gap-2 hover:bg-primary" (click)="didClickUpload()">
    Continue
  </a>
  <span *ngIf="isLoading == true" class="loading loading-spinner loading-sm"></span>
</div>

<div class="p-6 bg-white min-h-screen">
	<div class="overflow-x-auto">
		<div *ngIf="rows.length == 0">
			<p class="text-sm text-gray-500 mb-4">The selected file must contain a header row with the following values:</p>
			<pre>firstName</pre>
			<pre>lastName</pre>
			<pre>dateOfBirth</pre>
			<pre>state</pre>
			<pre>memberId</pre>
			<pre>payerId</pre>
			<pre>placeOfServiceCode</pre>
			<pre>specialtyCode</pre>
		</div>
	    <table *ngIf="rows.length > 0" class="table w-full">
			<thead class="bg-gray-200 rounded-lg">
				<tr class="rounded-lg border-b-0">
					<td class="rounded-l-lg">First Name</td>
					<td>Last Name</td>
					<td>Date Of Birth</td>
					<td>State</td>
					<td>Member ID</td>
					<td>Payer</td>
					<td>Place of Service Code</td>
					<td>Specialty Code</td>
					<td>Status</td>
					<td class="rounded-r-lg"></td>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of rows">
					<td [ngClass]="{ 'bg-red-400 text-white': row.param == 'firstName' }">{{ row.firstName }}</td>
					<td [ngClass]="{ 'bg-red-400 text-white': row.param == 'lastName' }">{{ row.lastName }}</td>
					<td [ngClass]="{ 'bg-red-400 text-white': row.param == 'dateOfBirth' }">{{ row.dateOfBirth }}</td>
					<td [ngClass]="{ 'bg-red-400 text-white': row.param == 'state' }">{{ row.state }}</td>
					<td [ngClass]="{ 'bg-red-400 text-white': row.param == 'memberId' }">{{ row.memberId }}</td>
					<td [ngClass]="{ 'bg-red-400 text-white': row.param == 'payerId' }">{{ this.payerMap[row.payerId] }}</td>
					<td [ngClass]="{ 'bg-red-400 text-white': row.param == 'placeOfServiceCode' }">{{ row.placeOfServiceCode }}</td>
					<td [ngClass]="{ 'bg-red-400 text-white': row.param == 'specialtyCode' }">{{ row.specialtyCode }}</td>
					<td>{{ row.status }}</td>
					<td *ngIf="row.verificationId == undefined"></td>
					<td *ngIf="row.verificationId">
						<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" [routerLink]="['/verifications', row.verificationId]" target="_blank">Open</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
