import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { map } from 'rxjs/operators'
import { NetworkStatusEvent, NetworkStatusFullRule, NetworkStatusRecord, NetworkStatusRecordFilters, NetworkStatusRecordFiltersValues, NetworkStatusRecordStatus, NetworkStatusRequestStatsRecord, NetworkStatusRuleCondition } from '../types/network-records';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NetworkRecordsService {

  constructor(
    private networkService: NetworkService
  ) {}

  async getNetworkRecords(filters: NetworkStatusRecordFilters): Promise<{ records: NetworkStatusRecord[], total: number}> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`network`, {
        params: new HttpParams({
          fromObject: {
            ...filters
          }
        })
      }).pipe(
        map((response: any) => {
          return response
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getNetworkRecordsStats(): Promise<NetworkStatusRequestStatsRecord[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`network/stats`).pipe(
        map((response: any) => {
          return response.stats
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getNetworkRecordsFiltersValues(): Promise<NetworkStatusRecordFiltersValues> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`network/filters`).pipe(
        map((response: any) => {
          return response.filters
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateNetworkStatusRecordStatuses(
    status: NetworkStatusRecordStatus,
    records: number[]
   ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put('network/', {
        status,
        records
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateNetworkStatusRecordNetworkPayerId(
    recordId: number,
    networkPayerId: string
   ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put(`network/${recordId}`, {
        networkPayerId
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getNetworkStatusEventsForRecord(recordId: string): Promise<NetworkStatusEvent[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`network/events/${recordId}`).pipe(
        map((response: any) => {
          return response.events
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getNetworkRules(offset: number): Promise<{ rules: NetworkStatusFullRule[], total: number}> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`network/rules`, {
        params: new HttpParams({
          fromObject: {
            offset
          }
        })
      }).pipe(
        map((response: any) => {
          return response
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createNetworkRule(
    status: NetworkStatusRecordStatus,
    name: string,
    conditions: Pick<NetworkStatusRuleCondition, 'key' | 'value'>[]
   ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post('network/rules', {
        status,
        name,
        conditions
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateNetworkRule(
    ruleId: number,
    status: NetworkStatusRecordStatus,
    name: string,
    conditions?: Pick<NetworkStatusRuleCondition, 'key' | 'value'>[]
   ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post('network/rules', {
        id: ruleId,
        status,
        name,
        conditions
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async deleteNetworkRule(ruleId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.delete(`network/rules/${ruleId}`).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

}
