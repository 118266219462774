export class Fact {

	constructor(
		public timestamp: Date,
		public count: number
	) {}

	get dateLabel(): string {
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
		return `${months[this.timestamp.getMonth()]} ${this.timestamp.getDate()}`
	}

	static fromJson(data: any): Fact {
		return new Fact(
			new Date(data.timestamp),
			data.count
		)
	}
}
