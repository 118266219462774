import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { SettingsComponent } from './settings/settings.component';
import { VerificationComponent } from './verification/verification.component';
import { CreatePatientComponent } from './create-patient/create-patient.component';
import { CreateVerificationsComponent } from './create-verifications/create-verifications.component';
import { PayersComponent } from './payers/payers.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';
import { WebhooksComponent } from './webhooks/webhooks.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { AuthenticatorComponent } from './authenticator/authenticator.component';
import { TotpComponent } from './totp/totp.component';
import { NetworkRecordsComponent } from './network-records/network-records.component';
import { NetworkStatusViewComponent } from './network-status-view/network-status-view.component';
import { NetworkRulesComponent } from './network-rules/network-rules.component';
import { ProvidersComponent } from './providers/providers.component';
import { SelectPayerComponent } from './@shared/modules/select-payer/select-payer.component';
import { CreateProviderRecordComponent } from './create-provider-record/create-provider-record.component';
import { PageSelectorComponent } from './@shared/modules/page-selector/page-selector.component';
import { CreateRuleComponent } from "./@shared/modules/create-rule/create-rule.component"

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HomeComponent,
    SettingsComponent,
    VerificationComponent,
    CreatePatientComponent,
    CreateVerificationsComponent,
    PayersComponent,
    ResetPasswordComponent,
    ForgottenPasswordComponent,
    WebhooksComponent,
    SetPasswordComponent,
    AuthenticatorComponent,
    TotpComponent,
    NetworkStatusViewComponent,
    NetworkRecordsComponent,
    NetworkRulesComponent,
    ProvidersComponent,
    SelectPayerComponent,
    CreateProviderRecordComponent,
    PageSelectorComponent,
    CreateRuleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
